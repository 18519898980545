export class DTEConfig {
    private _marketplaceGatewayUrl: string;

    get marketplaceGatewayUrl(): string {
        return this._marketplaceGatewayUrl;
    }

    set marketplaceGatewayUrl(value: string) {
        this._marketplaceGatewayUrl = value;
    }

    private _identifier: string;

    get identifier(): string {
        return this._identifier;
    }

    set identifier(value: string) {
        this._identifier = value;
    }

    private _basePath: string;

    get basePath(): string {
        return this._basePath;
    }

    set basePath(value: string) {
        this._basePath = value;
    }

    private _appVersion: string;

    get appVersion(): string {
        return this._appVersion;
    }

    set appVersion(value: string) {
        this._appVersion = value;
    }

    private _publicKey: string;

    get publicKey(): string {
        return this._publicKey;
    }

    set publicKey(value: string) {
        this._publicKey = value;
    }
}
