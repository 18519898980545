import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {EntitySelectionComponent} from '../auth/entity-selection/entity-selection.component';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {AuthService} from '../auth/services/auth.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ResetPasswordComponent} from '../user/reset-password/reset-password.component';
import {ConfigServiceService} from './config-service.service';
import {SharedDataService} from './shared-data.service';

const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class CommonServiceService {

    public userEntityList: any[] = [];
    private isValidatedPasswordForEdit: any;
    private messageSource = new BehaviorSubject('');
    currentMessage = this.messageSource.asObservable();
    private searchSource = new BehaviorSubject('');
    private toggleSource = new BehaviorSubject(false);
    currentCollapse = this.toggleSource.asObservable();

    private readonly DMS_URL: string;
    private readonly UMS_URL: string;
    private readonly BASE_URL: string;

    constructor(
        private http: HttpClient,
        private snackBar: MatSnackBar,
        public router: Router,
        private authService: AuthService,
        private ngxService: NgxUiLoaderService,
        private sharedDataService: SharedDataService,
        public dialog: MatDialog) {
        this.BASE_URL = ConfigServiceService.marketplaceGatewayUrl;
    }

    changeMessage(message: string) {
        this.messageSource.next(message)
    }

    searchText(search: string) {
        this.searchSource.next(search)
    }

    collapseMenu(collapse: boolean) {
        this.toggleSource.next(collapse)
    }

    snackBarSuccessMessage(message: string) {
        this.snackBar.open(message, '', {
                duration: 5000,
                panelClass: ['success-snackbar']
            }
        );
    }

    snackBarErrorsMessage(message: string) {
        if (message !== undefined) {
            this.snackBar.open(message, '', {
                    duration: 5000,
                    panelClass: ['failed-snackbar']
                }
            );
        }
    }

    snackBarWarningMessage(message: string) {
        this.snackBar.open(message, '', {
                duration: 5000,
                panelClass: ['warning-snackbar']
            }
        );
    }

    setValidatePasswordForEditData(data: any) {
        this.isValidatedPasswordForEdit = data;
    }

    getValidatePasswordForEditData() {
        return this.isValidatedPasswordForEdit;
    }

    getUserAllEntity() {
        this.ngxService.start();
        this.authService.getUserEntityList()
            .subscribe(res => {
                this.userEntityList = res;
                if (this.userEntityList.length > 1) {

                    const entityData = helper.decodeToken(JSON.stringify(window.sessionStorage.getItem('currentToken')));
                    this.dialog.open(EntitySelectionComponent,
                        {
                            width: '100px',
                            data: {
                                entityList: this.userEntityList,
                                defaultEntityId: entityData.selected_entity,
                                defaultEntityType: entityData.user_type,
                                showCloseBtn: true
                            },
                            panelClass: 'switch-tenant-modal',
                            backdropClass: 'server-selection-model-overlay',
                            disableClose: true
                        }
                    );
                }
                this.ngxService.stop();
            }, err => {
                this.ngxService.stop();
                this.snackBarErrorsMessage(err.error.errorDesc);
            });
    }

    checkIsNameExist(entityName: any, entityTypeName: any): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/check-entity-name-exist?entityName=' + entityName + '&entityTypeName=' + entityTypeName;
        return this.http.get<any>(url).pipe(
            tap((application: any) => console.log('check exist name')),
        );
    }

    manageLoginInitials(isDefaultUser: any, secQuestionsSetAtInitLogin: any) {
        const decodeToken = this.sharedDataService.getDecodedToken();

        if (decodeToken.selected_entity == null) {
            this.getUserAllEntity();
        }

        if (decodeToken.force_pwd_reset === true) {
            this.forceResetPassword();
        }
    }

    forceResetPassword() {
        this.dialog.open(ResetPasswordComponent,
            {
                width: '100px',
                data: {},
                panelClass: 'confirmation-modal',
                backdropClass: 'server-selection-model-overlay',
                disableClose: true

            }
        );
    }

    getEnterpriseUserSummary(): Observable<any> {
        const url = this.UMS_URL + '/user-mgt/enterprise-user-summary';
        return this.http.get<any>(url).pipe(
            tap((application: any) => console.log('enterprise-user-summary')),
        );
    }

    getEnterpriseSummary(): Observable<any> {
        const url = this.UMS_URL + '/user-mgt/enterprise-summary';
        return this.http.get<any>(url).pipe(
            tap((application: any) => console.log('enterprise-summary')),
        );
    }

    totalProductSummary(): Observable<any> {
        const url = this.UMS_URL + '/public/api/product/dte/report/get_stat_report';
        return this.http.get<any>(url).pipe(
            tap((_: any) => console.log('product-summary')),
        );
    }

    getUserComporitionSummary(): Observable<any> {
        const url = this.UMS_URL + '/user-mgt/user-composition';
        return this.http.get<any>(url).pipe(
            tap((application: any) => console.log('user-composition')),
        );
    }


    getUserSummaryGraphData(fromDate: any, toDate: any): Observable<any> {
        const url = this.UMS_URL + '/user-mgt/user-summary-graph?fromDate=' + fromDate + '&toDate=' + toDate;
        return this.http.get<any>(url).pipe(
            tap((application: any) => console.log('user-summary-graph')),
        );
    }

    getNewUserRegistrationSummaryData(fromDate: any, toDate: any): Observable<any> {
        const url = this.UMS_URL + '/user-mgt/user-registration-summary?fromDate=' + fromDate + '&toDate=' + toDate;
        return this.http.get<any>(url).pipe(
            tap((application: any) => console.log('user-summary-graph')),
        );
    }

    uploadFile(formData: any) {
        const url = this.BASE_URL + '/content/document';
        return this.http.post<any>(url, formData);
    }
}
