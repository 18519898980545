export class User {
    id: number;
    username: string
    firstName: string
    lastName: string
    email: string
    userLevel: string
    mobile: number
    lastActivity: string
    gitAccessEnable: string
    userType?: string;
    apiGatewayEnable: string
    userEntities: [{
        entitlements: [{
            group: {
                name: String;
                permissions: [{
                    id: number;
                    name: string;
                    description: string;
                    displayName: string;
                }];
            },
            permission: [
                {
                    id: number;
                    name: string;
                    description: string;
                    displayName: string;
                }
            ]
        }]
    }];


    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

