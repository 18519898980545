import {Validators} from '@angular/forms';
import {FileValidator} from 'ngx-material-file-input';
import {
    ValidateConfigUploadFile,
    ValidateDocumentUploadFile,
    ValidateSwaggerUploadFile,
    ValidateUploadFile,
    ValidateZipUploadFile,
} from '../validator/upload-file.validator';

export const FILE_SIZE = 2097152;

export const IMG_FILE_SIZE = 1048576;
export const BULK_UPLOAD_FILE_MAXIMUM_LIMIT_IN_MB = 20;
export const FILE_TYPES = ['.zip', '.gz', ''];
export const SWAGGER_FILE_TYPES = ['.yml', '.yaml', ''];
export const IMAGE_TYPES = [
    '.tif',
    '.tiff',
    '.bmp',
    '.jpg',
    '.jpeg',
    '.gif',
    '.png',
    '.eps',
    '.raw',
    null,
];
export const IMAGE_VALIDATOR = [
    FileValidator.maxContentSize(FILE_SIZE),
    ValidateUploadFile,
];
export const ZIP_FILE_VALIDATOR = [
    FileValidator.maxContentSize(FILE_SIZE),
    ValidateZipUploadFile,
];
export const SWAGGER_FILE_VALIDATOR = [
    FileValidator.maxContentSize(FILE_SIZE),
    ValidateSwaggerUploadFile,
];
export const CONFIG_FILE_SIZE = 20480;
export const CONFIG_FILE_TYPES = ['.txt', ''];
export const CONFIG_FILE_VALIDATOR = [
    Validators.required,
    FileValidator.maxContentSize(CONFIG_FILE_SIZE),
    ValidateConfigUploadFile,
];

export const DOCUMENT_FILE_SIZE = 5242880;
export const DOCUMENT_FILE_TYPES = ['.pdf'];
export const DOCUMENT_FILE_VALIDATOR = [
    FileValidator.maxContentSize(DOCUMENT_FILE_SIZE),
    ValidateDocumentUploadFile,
];

export const USER_ROLE = {
    MERCHANT_ADMIN: 'ROLE_MERCHANT_ADMIN',
    TENANT_ADMIN: 'ROLE_TENANT_ADMIN',
    OPERATION_ADMIN: 'ROLE_OPERATION_ADMIN'
}

export const USER_ROUTES = {
    USER_MGT: '/secure/users',
    ADD_USER: '/secure/users/add',
    VIEW_USER: '/secure/users/view',
    EDIT_USER: '/secure/users/edit',
    USER_PROFILE: '/secure/users/profile',
}

export const DASHBOARD_ROUTES = {
    DASHBOARD: '/secure/dashboard',
}

export const CUSTOMER_ROUTES = {
    CUSTOMER_MGT: '/secure/customer-management',
}

export const PARTNER_ROUTES = {
    PARTNER_MGT: '/secure/partner-management/partner',
    ADD_PARTNER: '/secure/partner-management/add',
    EDIT_PARTNER: '/secure/partner-management/edit',
    REVIEW_PARTNER: '/secure/partner-management/review',
    COMMISSION_SETTINGS: '/secure/partner-management/commission-settings',
}

export const ORDER_ROUTES = {
    ORDER_MGT: '/secure/order-management',
    PARTNER_ORDERS: '/secure/order-management/packages',
}

export const PRODUCT_ROUTES = {
    PRODUCT_MGT: '/secure/product-management',
    BULK_PRODUCT_MGT: '/secure/digital-stack/retail-product/bulk-add',
    BULK_IMAGE_UPLOAD: '/secure/digital-stack/retail-product/bulk-image-upload',
}

export const TASK_ROUTES = {
    TASK_MGT: '/secure/task-management',
    REGISTRATION: '/secure/task-management/user-registration-task-list',
}

export const SETTINGS_ROUTES = {
    SETTINGS: '/secure/settings',
}

export const CATEGORY_ROUTES = {
    CATEGORY_MGT: '/secure/category-management',
    CATEGORY_LIST: '/secure/category-management/category',
    ATTRIBUTES_LIST: '/secure/category-management/attributes',
    ADD_ATTRIBUTE: '/secure/category-management/attributes/add',
    ADD_CATEGORY: '/secure/category-management/category/add',
}

export const PROMO_ROUTES = {
    PROMO_MGT: '/secure/promotions-and-campaigns',
    PROMOTIONS_LIST: '/secure/promotions-and-campaigns/promotions',
    CAMPAIGNS_LIST: '/secure/promotions-and-campaigns/campaigns',
    ADD_CAMPAIGN: '/secure/promotions-and-campaigns/campaign-create',
    ADD_PROMOTION: '/secure/promotions-and-campaigns/promotion-create',
}

export const INVENTORY_ROUTES = {
    INVENTORY_MGT: '/secure/inventory-management',
}

export const REPORT_ROUTES = {
    REPORT_MGT: '/secure/report-management',
}
