// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_text {
  color: #58595b;
  font-size: 30px;
  font-weight: bold;
  margin-top: 40px;
}

.error_subtext {
  color: #f15a22;
  font-size: 16px;
}

.empty-content {
  left: 50%;
  margin-right: -50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/service-error/service-error.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,SAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,gCAAA;AACF","sourcesContent":[".error_text {\n  color: #58595b;\n  font-size: 30px;\n  font-weight: bold;\n  margin-top: 40px;\n}\n\n.error_subtext {\n  color: #f15a22;\n  font-size: 16px;\n}\n\n.empty-content {\n  left: 50%;\n  margin-right: -50%;\n  position: absolute;\n  text-align: center;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
