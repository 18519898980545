import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PermissionsDirective} from './directives/permissions.directive';
import {ButtonWidgetDirective} from './directives/button-widget.directive';
import {RightTopWidgetDirective} from './directives/right-top-widget.directive';
import {BreadcrumbDirective} from './directives/breadcrumb.directive';
import {IconCreatorDirective} from './directives/icon-creater.directive';
import {FormLeftPanelDirective} from './directives/form-left-panel.directive';
import {FormRightPanelDirective} from './directives/form-right-panel.directive';
import {MinHourPipe} from './pipe/min-hour.pipe';
import {SearchDataPipe} from './pipe/search-data.pipe';
import {ServiceErrorComponent} from './service-error/service-error.component';
import {DateFormatPipe} from './pipe/date-format.pipe';
import {UploadFileValidatorDirective} from './directives/upload-file-validator.directive';
import {TooltipListPipe} from './pipe/tooltip-list.pipe';
import {SafePipe} from './pipe/SafePipe';
import {SecMinHourPipe} from './pipe/sec-min-hour.pipe';
import {DateTimeFormatPipe} from './pipe/date-time-format.pipe';

@NgModule({
    declarations: [
        PermissionsDirective,
        ButtonWidgetDirective,
        RightTopWidgetDirective,
        BreadcrumbDirective,
        IconCreatorDirective,
        FormLeftPanelDirective,
        FormRightPanelDirective,
        MinHourPipe,
        SearchDataPipe,
        ServiceErrorComponent,
        DateFormatPipe,
        UploadFileValidatorDirective,
        TooltipListPipe,
        SafePipe,
        SecMinHourPipe,
        DateTimeFormatPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PermissionsDirective,
        ButtonWidgetDirective,
        RightTopWidgetDirective,
        BreadcrumbDirective,
        IconCreatorDirective,
        FormLeftPanelDirective,
        FormRightPanelDirective,
        MinHourPipe,
        SearchDataPipe,
        ServiceErrorComponent,
        DateFormatPipe,
        TooltipListPipe,
        SafePipe,
        SecMinHourPipe,
        DateTimeFormatPipe
    ]
})
export class SharedModule {
}
