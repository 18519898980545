import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-form-element-list-loader',
    templateUrl: './form-element-list-loader.component.html',
    styleUrls: ['./form-element-list-loader.component.scss']
})
export class FormElementListLoaderComponent implements OnInit {

    constructor() {
    }


    ngOnInit(): void {
    }

}
