import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PeriodicElement} from '../../user/user-add-permissions/user-add-permissions.component';
import {MatPaginator} from '@angular/material/paginator';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

    @Input('tableDataSource') dataSource: any;
    @Input('pageSizeOptions') pageSizeOptions: any;
    @Input('tableColumns') tableColumns: any;
    @Input('selectedItems') selection: any;
    @Input('isShowCheckbox') showCheckbox: boolean = false;
    @Input('columnDataList') columnDataList: any;
    @Input('tableSummary') tableSummary: any;
    @Input('isClickable') isClickable: any;

    @ViewChild('paginator', {static: false}) paginator: MatPaginator;

    constructor() {
    }

    ngOnInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    isAllPermissionSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterTogglePermissions() {
        this.isAllPermissionSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach((row: any) => this.selection.select(row));
    }

    checkboxLabel(row?: PeriodicElement): string {
        if (!row) {
            return `${this.isAllPermissionSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }
}
