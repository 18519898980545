import {Injectable} from '@angular/core';
import {DTEConfig} from '../models/DTEConfig';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {STORAGE_KEYS} from '../shared/constants';

@Injectable()
export class ConfigServiceService {

    constructor(private handler: HttpBackend) {
    }

    static get marketplaceGatewayUrl() {
        return ConfigServiceService._dteConfig.marketplaceGatewayUrl;
    }

    static set marketplaceGatewayUrl(value) {
        ConfigServiceService._dteConfig.marketplaceGatewayUrl = value;
    }

    static get identifier() {
        return ConfigServiceService._dteConfig.identifier;
    }

    static set identifier(value) {
        ConfigServiceService._dteConfig.identifier = value;
    }

    static get basePath(): string {
        return this._dteConfig.basePath;
    }

    static set basePath(value: string) {
        this._dteConfig.basePath = value;
    }

    set basePath(value: string) {
        this.dteConfig.basePath = value;
    }

    static get publicKey() {
        return ConfigServiceService._dteConfig.publicKey;
    }

    static set publicKey(value) {
        ConfigServiceService._dteConfig.publicKey = value;
    }

    private static _dteConfig: DTEConfig = new DTEConfig();

    get dteConfig(): DTEConfig {
        return ConfigServiceService._dteConfig;
    }

    set dteConfig(value: DTEConfig) {
        ConfigServiceService._dteConfig = value;
    }

    get appVersion(): string {
        return this.dteConfig.appVersion;
    }

    set appVersion(value: string) {
        this.dteConfig.appVersion = value;
    }

    get basePath(): string {
        return this.dteConfig.basePath;
    }

    async fetchConfig() {
        const promise = new HttpClient(this.handler)
            .get('config/config.json', {observe: 'response'})
            .toPromise()
            .then((response: any) => {
                const tenantId = response.headers.get('Tenant');
                this.dteConfig = response.body as DTEConfig;

                if (tenantId) {
                    localStorage.setItem(STORAGE_KEYS.tenantId, tenantId);
                }
            })
            .catch((error) => {
            });

        return promise;
    }
}
