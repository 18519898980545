import {Directive} from '@angular/core';
import {FormControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {FileValidator} from 'ngx-material-file-input';

@Directive({
    selector: '[appUploadFileValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: FileValidator, multi: true},
    ]
})
export class UploadFileValidatorDirective implements Validator {
    static validate(c: FormControl): { [key: string]: any } {
        return c.value == null || c.value.length === 0 ? {'required': true} : {};
    }

    validate(c: FormControl): { [key: string]: any } {
        return UploadFileValidatorDirective.validate(c);
    }

}
