import {AfterViewInit, Component, OnChanges, OnInit} from '@angular/core';
import {Location, PopStateEvent} from '@angular/common';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import PerfectScrollbar from 'perfect-scrollbar';
import {CommonServiceService} from '../../services/common-service.service';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnChanges, AfterViewInit {
    collapse = false;
    private _router: Subscription;
    private lastPoppedUrl: string | undefined = '';
    private yScrollStack: number[] = [];

    constructor(public location: Location, private router: Router,
                private commonService: CommonServiceService) {
    }

    ngOnInit() {
        // const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        //
        // if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {
        //     // if we are on windows OS we activate the perfectScrollbar function
        //
        //     document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
        // } else {
        //     document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
        // }

        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                if (event.url != this.lastPoppedUrl) {
                    this.yScrollStack.push(window.scrollY);
                }
            } else if (event instanceof NavigationEnd) {
                if (event.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, <number>this.yScrollStack.pop());
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });

        this.commonService.currentCollapse.subscribe(collapse => {
            this.collapse = collapse;
        })
    }

    ngAfterViewInit() {
        this.runOnRouteChange();
    }

    runOnRouteChange(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
            const ps = new PerfectScrollbar(elemMainPanel);
            //   let pfs = new PerfectScrollbar(notificationDropdown);
            ps.update();
        }
    }

    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    ngOnChanges(): void {
        this.commonService.currentCollapse.subscribe(collapse => {
            this.collapse = collapse;
        })
    }
}
