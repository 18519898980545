import {Injectable} from '@angular/core';
import * as forge from 'node-forge';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {
    constructor() {
    }

    /**
     * Encrypt data using RSAES-OAEP with SHA-256 and MGF1 with SHA-1.
     *
     * @param data
     * @param publicKey
     */
    encryptRSA(data: string, publicKey: string): string {
        const rsaPublicKey = forge.pki.publicKeyFromPem(publicKey);

        const encrypted = rsaPublicKey.encrypt(forge.util.encodeUtf8(data), 'RSA-OAEP', {
            md: forge.md.sha256.create(),
            mgf1: {
                md: forge.md.sha1.create()
            }
        });
        return forge.util.encode64(encrypted);
    }
}
