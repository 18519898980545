import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Entity} from '../../models/Entity';
import {UserRegularizePolicyConfig} from '../../models/UserRegularizePolicyConfig';
import {SharedDataService} from '../../services/shared-data.service';
import {AdminConfigurationControllerService} from '@adl-et-of/mkp-admin-gateway-service-lotus';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})


export class SettingsService {

    private readonly UMS_URL: string;
    private readonly DMS_URL: string;
    private readonly SMS_URL: string;

    constructor(private http: HttpClient,
                private _configService: AdminConfigurationControllerService,
                private shardDataService: SharedDataService) {
    }

    public createTenant(data: any): Observable<Entity> {
        const url = this.DMS_URL + '/deployment-mgt/tenants';
        return this.http.post<Entity>(url, data, httpOptions).pipe(
            tap((application: Entity) => console.log('created tenant')),
        );
    }

    public createDepartment(data: any): Observable<Entity> {
        const url = this.DMS_URL + '/deployment-mgt/divisions';
        return this.http.post<Entity>(url, data, httpOptions).pipe(
            tap((application: Entity) => console.log('created department')),
        );
    }

    // platform configurations

    public getAllSecurityQuestions(entityId: any): Observable<any> {
        const url = this.UMS_URL + '/user-mgt/security-questions?entityId=' + entityId;
        return this.http.get<any>(url).pipe(
            tap((config: any) => console.log('fetched questions')),
        );
    }

    public addUserReqularizationPolicy(data: any): Observable<UserRegularizePolicyConfig> {
        const url = this.UMS_URL + '/user-mgt/user-regularization-policy';
        return this.http.post<any>(url, data, httpOptions).pipe(
            tap((_: any) => console.log('configurations added')),
        );
    }

    public updateUserReqularizationPolicy(id: any, data: any): Observable<UserRegularizePolicyConfig> {
        const url = this.UMS_URL + '/user-mgt/user-regularization-policy/' + id;
        return this.http.put<any>(url, data, httpOptions).pipe(
            tap(_ => console.log(`updated configuration id=${id}`)),
        );
    }


    public getPoliciesByEntityID(entityId: any): Observable<UserRegularizePolicyConfig> {
        const url = this.UMS_URL + '/user-mgt/user-regularization-policy/' + entityId;
        return this.http.get<any>(url).pipe(
            tap((config: any) => console.log('fetched questions')),
        );
    }

    public addNewSecurityQuestions(data: any): Observable<any> {
        const url = this.UMS_URL + '/user-mgt/security-questions';
        return this.http.post<any>(url, data, httpOptions).pipe(
            tap((config: any) => console.log('question added')),
        );
    }

    public getEntityType(entityId: any): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/entities/' + entityId + '/entity-types';
        return this.http.get<any>(url).pipe(
            tap((config: any) => console.log('fetched questions')),
        );
    }

    // Time Zone

    public getAllTimeZones(): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/time-zones';
        return this.http.get<any>(url).pipe(
            tap((config: any) => console.log('fetched questions')),
        );
    }

    public getSelectedTimeZone(entityId: any): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/entities/' + entityId + '/time-zone';
        return this.http.get<any>(url).pipe(
            tap((config: any) => console.log('fetched questions')),
        );
    }

    public updateTimeZone(entityId: any, timeZoneId: any, data: any): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/entities/' + entityId + '/time-zones/' + timeZoneId;
        return this.http.put(url, data, httpOptions).pipe(
            tap(_ => console.log(`updated configuration id=${entityId}`)),
        );
    }

    public getAllDivisions(parentEntityId: any): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/divisions?parentEntityId=' + parentEntityId;
        return this.http.get<any>(url).pipe(
            tap((config: any) => console.log('fetched divisions')),
        );
    }

    /**
     * Delete division by id.
     *
     * @param divisionId
     * @returns {Observable}
     */
    public deleteDivision(divisionId: any): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/division/' + divisionId;
        return this.http.delete<any>(url, httpOptions).pipe(
            tap(_ => console.log(`delete division`)),
        );
    }

    public isDivisionSafeToDelete(divisionId: any): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/division/' + divisionId + '/safe-to-delete';
        return this.http.get<any>(url, httpOptions).pipe(
            tap(_ => console.log(`is safe to delete division`)),
        );
    }

    public getPoliciesByUserId(userId: any): Observable<UserRegularizePolicyConfig> {
        const url = this.UMS_URL + '/user-mgt/user-regularization-policy/users/' + userId;
        return this.http.get<UserRegularizePolicyConfig>(url).pipe(
            tap(_ => console.log('fetched user regularize policy by user id')),
        );
    }

    public getConfigurationByCategoryFromSMS(categoryName: any): Observable<any> {
        return this._configService.systemMgtConfigurationCategoryNameNameGet(categoryName).pipe(
            tap(_ => console.log('fetched configurations')),
        );
    }

    public UpdateConfigurationList(configurationList: any): Observable<any> {
        const url = this.SMS_URL + '/system-mgt/configurations';
        const authToken = this.shardDataService.getToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken
        });
        return this.http.put<any>(url, configurationList, {headers: headers}).pipe(
            tap(_ => console.log('fetched configurations')),
        );
    }

    public uploadThemingFiles(data: any): Observable<any> {
        const headers = new HttpHeaders({
            'Accept': 'application/json'
        });
        const url = this.SMS_URL + '/system-mgt/upload-files';
        return this.http.post<any>(url, data, {headers}).pipe(
            tap(_ => console.log('upload files')),
        );
    }


}
