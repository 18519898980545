// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-pw-form {
  margin-top: 30px;
}
.reset-pw-form p {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: center;
}
.reset-pw-form .reset-password-form {
  margin: 0 auto;
  width: 80%;
}
.reset-pw-form .input-container {
  display: flex;
}
.reset-pw-form .input-container .info-icon {
  float: right;
  height: 16px;
  width: 16px;
}

.welcome-tag {
  text-align: center;
}
.welcome-tag span {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.21;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/user/reset-password/reset-password.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAEE;EACE,cAAA;EACA,UAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AAGI;EACE,YAAA;EACA,YAAA;EACA,WAAA;AADN;;AAMA;EACE,kBAAA;AAHF;AAKE;EACE,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".reset-pw-form {\n  margin-top: 30px;\n\n  p {\n    font-size: 18px;\n    line-height: 1.2;\n    margin-bottom: 20px;\n    text-align: center;\n  }\n\n  .reset-password-form {\n    margin: 0 auto;\n    width: 80%;\n  }\n\n  .input-container {\n    display: flex;\n\n    .info-icon {\n      float: right;\n      height: 16px;\n      width: 16px;\n    }\n  }\n}\n\n.welcome-tag {\n  text-align: center;\n\n  span {\n    font-size: 22px;\n    font-weight: bold;\n    letter-spacing: normal;\n    line-height: 1.21;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
