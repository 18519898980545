import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {STORAGE_KEYS} from 'app/shared/constants';
import {User} from 'app/models/User';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AdminUserControllerService} from '@adl-et-of/mkp-admin-gateway-service-lotus';
import {TokenService} from './token.service';

const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {

    permissions: string;
    isTokenInvalid = false;
    createdMerchant: boolean = false;
    private userPermissions: any[] = [];
    private _localization: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        public router: Router,
        private adminUserService: AdminUserControllerService,
        private tokenService: TokenService
    ) {
    }

    public checkPermission(val: string) {
        const token = sessionStorage.getItem('currentToken');
        if (token != null) {
            // const decodeToken = helper.decodeToken(JSON.stringify(token))
            // const perm = decodeToken.entities[0].permissions;
            const perm: any = JSON.parse(sessionStorage.getItem('permissions') ?? '');
            this.permissions = val;
            if (perm) {
                perm.forEach((uPermission: any) => {
                    this.userPermissions.push(uPermission)
                });
            }
            return this.userPermissions.includes(this.permissions);
        }
    }

    // TODO:
    // public getUserEntityId() {
    //     const token = JSON.parse(sessionStorage.getItem('currentToken') ?? '');
    //     if (token != null) {
    //         const decodeToken = helper.decodeToken(JSON.stringify(token))
    //         // return decodeToken.selected_entity;
    //         return token.selected_entity;
    //     }
    // }

    public getDecodedToken() {
        const token = JSON.parse(sessionStorage.getItem('currentToken') ?? '');
        if (token != null) {
            // return helper.decodeToken(JSON.stringify(token));
            return token
        }
    }

    // TODO:
    // public getUserIdFromToken() {
    //     const token: any = JSON.parse(sessionStorage.getItem('currentToken') ? '' : '');
    //     if (token != null) {
    //         const decodeToken = helper.decodeToken(JSON.stringify(token))
    //         // return decodeToken.user_id;
    //         return token.user_id
    //     }
    // }
    //
    // public getUserNameFromToken() {
    //     const token = JSON.parse(sessionStorage.getItem('currentToken') ?? '');
    //     if (token != null) {
    //         const decodeToken = helper.decodeToken(JSON.stringify(token))
    //         // return decodeToken.user_name;
    //         return token.user_name;
    //     }
    // }
    //
    // public getUserTypeFromToken() {
    //     const token = JSON.parse(sessionStorage.getItem('currentToken') ?? '');
    //     if (token != null) {
    //         const decodeToken = helper.decodeToken(JSON.stringify(token))
    //         // return decodeToken.user_type;
    //         return token.user_type;
    //     }
    // }
    //
    // public getUserGroupFromToken() {
    //     const token = JSON.parse(sessionStorage.getItem('currentToken') ?? '');
    //     if (token != null) {
    //         const decodeToken = helper.decodeToken(JSON.stringify(token))
    //         // return decodeToken.user_group;
    //         return token.user_group;
    //     }
    // }

    public getTenantIdFromToken() {
        const token = JSON.parse(sessionStorage.getItem('currentToken') ?? '');
        if (token != null) {
            const decodeToken = helper.decodeToken(JSON.stringify(token))
            // return decodeToken.tenant_id;
            return token.tenant_id;
        }
    }

    public logout() {
        const tenantId = localStorage.getItem(STORAGE_KEYS.tenantId);
        sessionStorage.removeItem('currentToken');
        sessionStorage.clear();
        this.adminUserService.logout1().subscribe(res => {
                this.tokenService.removeToken();
                this.tokenService.removeRefreshToken();
                this.tokenService.removeMfaToken();
                this.tokenService.removeUserRole()
                this.tokenService.removeRolePermission();
                this.tokenService.removeMfaType();
                this.tokenService.removeUserMaskedNumber()
                this.router.navigate(['/auth'], {queryParams: {tenant: tenantId}}).then();
            },
        );
        // if (tenantId) {
        //     localStorage.setItem(STORAGE_KEYS.tenantId, tenantId);
        //     this.router.navigate(['/auth'], {queryParams: {tenant: tenantId}});
        // } else {
        //     this.router.navigate(['/auth']);
        // }
    }

    public flushStorageData() {
        sessionStorage.removeItem('currentToken');
    }

    public getToken() {
        const token = localStorage.getItem('fw_access_token');
        if (token != null) {
            return token;
        }
    }

    public getUserData(): Observable<any> {
        return this.adminUserService.getProfileDetails1()
            .pipe(map((userDetails: any) => {
                let userData;
                userData = new User({
                    email: userDetails.email,
                    last_name: userDetails.lastName,
                    first_name: userDetails.firstName,
                    user_name: userDetails.username,
                    mobile: userDetails.mobile
                });
                return userData;
            }));
    }

    // TODO:
    public isExpiredToken() {
        const token = JSON.parse(sessionStorage.getItem('currentToken') ?? '')
        if (token != null) {
            return helper.isTokenExpired(token.token)
        }
        return true;
        // return token.accessToken === null;
    }

    public storeToken(tokenName: any, token: any) {
        sessionStorage.setItem(tokenName, token);
    }

    public setCustomParam(paramName: any, paramValue: any) {
        sessionStorage.setItem(paramName, paramValue);
    }

    // TODO:
    // public getCustomParam(paramName: any) {
    //     return JSON.parse(sessionStorage.getItem(paramName) ?? '');
    // }
    //
    // public removeCustomParam(paramName: any) {
    //     sessionStorage.removeItem(paramName);
    // }
    //
    // setTokenInvalid() {
    //     this.isTokenInvalid = true;
    // }
    //
    // setActivatedFeatures(activatedFeatureList: string) {
    //     sessionStorage.setItem('active-features', activatedFeatureList);
    // }
    //
    // checkActivatedRoutes(featureCode: string): boolean {
    //     if (featureCode == null) {
    //         return true;
    //     }
    //     const activeFeatureListStr: any = sessionStorage.getItem('active-features');
    //     const activeFeatureList = activeFeatureListStr.split(',');
    //     return activeFeatureList.includes(featureCode);
    // }

    getLocalization() {
        return this._localization.pipe(switchMap(localization => {
            if (localization) {
                return this._localization;
            }
            return this.loadLocalization();
        }));
    }

    loadLocalization() {
        const currentToken = this.getToken();
        if (currentToken !== undefined) {
            // return this.configurationService.systemMgtConfigurationCategoryNameNameGet('LOCALIZATION')
            //     .pipe(tap(resp => {
            //         if (resp) {
            //             this.setLocalization(resp);
            //         }
            //     }));
        } else {
            // return this.smsService.getConfigurationByCategoryFromSMS('LOCALIZATION')
            //     .pipe(tap(resp => {
            //         if (resp) {
            //             this.setLocalization(resp);
            //         }
            //     }));
        }
        return []
    }

    setLocalization(localization: any) {
        this._localization.next(localization);
    }

    setCreatedPartner() {
        this.createdMerchant = true;
    }

    // TODO:
    // getCreatedPartner() {
    //     return this.createdMerchant;
    // }
}
