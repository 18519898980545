import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppMaterialModule} from '../app-material/app-material.module';
import {TableComponent} from './table/table.component';
import {LiveChatComponent} from './live-chat/live-chat.component';
import {CategoryTreeComponent} from './category-tree/category-tree.component';
import {MatMenuModule} from '@angular/material/menu';
import {ProductViewComponent} from './product-view/product-view.component';
import {TableLoaderComponent} from './table-loader/table-loader.component';
import {ShowImageOnHoverComponent} from './show-image-on-hover/show-image-on-hover.component';
import {FormElementListLoaderComponent} from './form-element-list-loader/form-element-list-loader.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPermissionsModule.forRoot(),
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        MatMenuModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        TableComponent,
        LiveChatComponent,
        CategoryTreeComponent,
        ProductViewComponent,
        TableLoaderComponent,
        ShowImageOnHoverComponent,
        FormElementListLoaderComponent,
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        TableComponent,
        LiveChatComponent,
        CategoryTreeComponent,
        ProductViewComponent,
        TableLoaderComponent,
        ShowImageOnHoverComponent,
        FormElementListLoaderComponent
    ]
})
export class ComponentsModule {
}
