import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-common-errors',
    templateUrl: './common-errors.component.html',
    styleUrls: ['./common-errors.component.scss']
})
export class CommonErrorsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
