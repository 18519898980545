import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AdminProductControllerService} from '@adl-et-of/mkp-admin-gateway-service-lotus';
import {CommonService} from '../../digital-stack/services/common.service';

@Component({
    selector: 'app-product-view',
    templateUrl: './product-view.component.html',
    styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {
    product: any;
    thumbnailImage: any;
    primaryImage: any;
    otherImagesArray: any[] = [];
    skus: any[] = [];
    images: any[] = [];
    activeImageIndex = 0;
    highlightDetails: string;
    specifications: string;
    loader = false;
    count = 1

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        public ngxUiLoaderService: NgxUiLoaderService,
        public commonService: CommonService,
        public adminProductControllerService: AdminProductControllerService,
        public dialogRef: MatDialogRef<ProductViewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit() {
        // alert(this.data.id)
        this.getProductDetails(this.data.id)
    }

    getProductDetails(id: any, isNext?: any) {
        this.loader = true;
        this.adminProductControllerService.getProductById(id).subscribe({
            next: this.handleProductDetails.bind(this),
            error: this.handleError.bind(this)
        });
    }

    handleProductDetails(data: any) {
        this.loader = false;
        this.getProdcutImages(this.data.id)
        this.product = data.data;
        let skus_array: any[] = [];
        skus_array = this.transformAttributes(this.product.skus);
        skus_array.forEach((item, index) => {
            skus_array[index].active_value = skus_array[index].values[0];
        });
        this.skus = skus_array.map(item => ({...item}));
        this.highlightDetails = this.product.productAdditionalDetails.find((item: any) => item.name === 'Highlights & Details').value;
        this.specifications = this.product.productAdditionalDetails.find((item: any) => item.name === 'Specifications').value;
    }

    handleError(error: any) {
        this.loader = false;
        if (error?.error?.message) {
            this.commonService.snackBarErrorsMessage(error?.error?.message);
        } else {
            this.commonService.snackBarErrorsMessage('something went wrong');
        }
    }

    getProdcutImages(id: any) {
        this.loader = true;
        this.adminProductControllerService.getProductImages(id).subscribe({
            next: this.handleProductImages.bind(this),
            error: this.handleError.bind(this)
        });
    }

    handleProductImages(data: any) {
        this.loader = false;
        this.otherImagesArray = [];
        if (data) {
            const images = data?.images
            if (images?.primary_image) {
                this.images.push(images.primary_image)
            }
            if (images?.other_images?.length > 0) {
                images?.other_images.forEach((img: any) => {
                    this.images.push(img.image)
                });
            }
        }
    }


    transformAttributes(data: { attributes: any[] }[]): any[] {
        const attributeMap: { [key: string]: any } = {};

        data.forEach(item => {
            item.attributes.forEach(attr => {
                if (!attributeMap[attr.attribute_name]) {
                    attributeMap[attr.attribute_name] = {
                        attributes_name: attr.attribute_name,
                        type: attr.type,
                        values: [],
                        active_value: null
                    };
                }
                const existingValues = attributeMap[attr.attribute_name].values;
                const isDuplicate = existingValues.some((valueObj: any) => valueObj.value === attr.value);
                if (!isDuplicate) {
                    attributeMap[attr.attribute_name].values.push({
                        value: attr.value,
                        value_name: attr.value_name
                    });
                }
            });
        });
        return Object.values(attributeMap);
    }

    setActive(index: number, value: any) {
        this.skus[index].active_value = value;
    }
}
