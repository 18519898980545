import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        if (!value) {
            return 'NA';
        }

        let date = new Date(value);
        date = new Date(date.setMinutes(date.getMinutes()))

        const singleDigitSeconds = date.getSeconds();
        var doubleDigitSeconds: any;
        if (singleDigitSeconds < 10) {
            doubleDigitSeconds = '0' + singleDigitSeconds;
        } else {
            doubleDigitSeconds = singleDigitSeconds;
        }

        const singleDigitMinutes = date.getMinutes();
        var doubleDigitMinutes: any;
        if (singleDigitMinutes < 10) {
            doubleDigitMinutes = '0' + singleDigitMinutes;
        } else {
            doubleDigitMinutes = singleDigitMinutes;
        }

        const singleDigitHours = date.getHours();
        var doubleDigitHours: any;
        if (singleDigitHours < 10) {
            doubleDigitHours = '0' + singleDigitHours;
        } else {
            doubleDigitHours = singleDigitHours;
        }

        const singleDigitDay = date.getDate();
        var doubleDigitDay: any;
        if (singleDigitDay < 10) {
            doubleDigitDay = '0' + singleDigitDay;
        } else {
            doubleDigitDay = singleDigitDay;
        }

        const stringDate = doubleDigitDay + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear();
        const stringTime = doubleDigitHours + ':' + doubleDigitMinutes + ':' + doubleDigitSeconds;
        return stringTime + ' | ' + stringDate;
    }

}
