// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: relative;
  min-width: 160px;
  overflow: auto;
  z-index: 1;
  padding: 0;
  margin-top: 3px;
}

.dropdown-content a {
  color: #707070;
  opacity: 1;
  padding: 4px 0 4px 20px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.show {
  display: block;
}

.dropdown-content a:hover {
  color: #034EA1;
  background-color: #ffffff;
}

.dropdown-content a:active {
  color: #034EA1;
  background-color: #ffffff;
}

.logo {
  display: flex;
  height: 72px;
  background-color: #fafbfc;
}

.nav-link {
  display: flex;
}

.caretup {
  transform: rotate(180deg);
}

.nav .sidebar-dropdown {
  height: 10px;
  width: 10px;
  margin: auto 10px auto auto;
}

/*.adl-logo {*/
/*    width: 200px;*/
/*}*/

.adl-logo img {
  width: auto !important;
}

.sidebar .nav li a img, .sidebar .nav li .dropdown-menu a img {
  height: 32px;
  width: 32px;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/sidebar/sidebar.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,UAAU;EACV,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,uBAAuB;EACvB,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,2BAA2B;AAC7B;;AAEA,cAAc;AACd,oBAAoB;AACpB,IAAI;;AAEJ;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,UAAU;AACZ","sourcesContent":[".dropdown {\n  position: relative;\n  display: inline-block;\n}\n\n.dropdown-content {\n  display: none;\n  position: relative;\n  min-width: 160px;\n  overflow: auto;\n  z-index: 1;\n  padding: 0;\n  margin-top: 3px;\n}\n\n.dropdown-content a {\n  color: #707070;\n  opacity: 1;\n  padding: 4px 0 4px 20px;\n  text-decoration: none;\n  display: block;\n  font-size: 14px;\n}\n\n.show {\n  display: block;\n}\n\n.dropdown-content a:hover {\n  color: #034EA1;\n  background-color: #ffffff;\n}\n\n.dropdown-content a:active {\n  color: #034EA1;\n  background-color: #ffffff;\n}\n\n.logo {\n  display: flex;\n  height: 72px;\n  background-color: #fafbfc;\n}\n\n.nav-link {\n  display: flex;\n}\n\n.caretup {\n  transform: rotate(180deg);\n}\n\n.nav .sidebar-dropdown {\n  height: 10px;\n  width: 10px;\n  margin: auto 10px auto auto;\n}\n\n/*.adl-logo {*/\n/*    width: 200px;*/\n/*}*/\n\n.adl-logo img {\n  width: auto !important;\n}\n\n.sidebar .nav li a img, .sidebar .nav li .dropdown-menu a img {\n  height: 32px;\n  width: 32px;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
