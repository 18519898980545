import {Validators} from '@angular/forms';
import {FileValidator} from 'ngx-material-file-input';
import {ValidateConfigUploadFile, ValidateDocumentUploadFile, ValidateUploadFile} from './validator/upload-file.validator';


export const FILE_SIZE = 536870912;
export const FILE_TYPES = ['.zip', '.gz', ''];
export const FILE_VALIDATOR = [Validators.required, FileValidator.maxContentSize(FILE_SIZE), ValidateUploadFile];
export const CONFIG_FILE_SIZE = 20480;
export const CONFIG_FILE_TYPES = ['.txt', ''];
export const CONFIG_FILE_VALIDATOR = [Validators.required, FileValidator.maxContentSize(CONFIG_FILE_SIZE), ValidateConfigUploadFile];
export const DOCUMENT_FILE_SIZE = 5242880;
export const DOCUMENT_FILE_TYPES = ['.txt', '.pdf', '.doc', '.docx', ''];
export const DOCUMENT_FILE_VALIDATOR = [Validators.required, FileValidator.maxContentSize(DOCUMENT_FILE_SIZE), ValidateDocumentUploadFile];

// Image urls for ManagementView Component
export const IMAGE_BASE_URL = '/../../assets/img/management-view/';
export const ADDONS_URL = IMAGE_BASE_URL + 'add-on.png'
export const APPLICATION_URL = IMAGE_BASE_URL + 'applications.png'
export const ASSIGNED_TASKS_URL = IMAGE_BASE_URL + 'assigned-tasks.png'
export const MICROSERVICES_URL = IMAGE_BASE_URL + 'microservices.png'
export const TENANT_URL = IMAGE_BASE_URL + 'tenants.png'
export const TEMPLATE_URL = IMAGE_BASE_URL + 'templates.png'
export const USERS_URL = IMAGE_BASE_URL + 'users.png'
export const DEPARTMENTS_URL = IMAGE_BASE_URL + 'department.png'
export const ERROR_IMAGE = IMAGE_BASE_URL + 'error.svg'
export const ERROR_MSG = IMAGE_BASE_URL + 'no-data.png'
export const ERROR_MSG_DEPLOYMENT = IMAGE_BASE_URL + 'deploymentError.svg'
export const ERROR_IMAGE_PROJECT = IMAGE_BASE_URL + 'projectErrorImage.svg'

// ams feature version
export const AMS_FEATURES_SHOW_API_V2 = 'AMS_FEATURES_SHOW_API_V2';

export const STORAGE_KEYS = {
    tenantId: 'tenantId'
}



