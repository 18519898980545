import {Component, Inject, OnInit} from '@angular/core';
import {ResetPasswordComponent} from '../../user/reset-password/reset-password.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
    days: number;
}

@Component({
    selector: 'app-password-expire',
    templateUrl: './password-expire.component.html',
    styleUrls: ['./password-expire.component.scss']
})
export class PasswordExpireComponent implements OnInit {

    expireDays = 0;

    constructor(
        public dialogRef: MatDialogRef<PasswordExpireComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
        if (this.data != null) {
            this.expireDays = this.data.days;
        }
    }

    changePassword(): void {
        const dialogRef = this.dialog.open(ResetPasswordComponent, {
            width: '250px',
            data: {passwordExpire: true},
            panelClass: 'confirmation-modal',
            backdropClass: 'server-selection-model-overlay'
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

}
