import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {STORAGE_KEYS} from 'app/shared/constants';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class TenantGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.validateTenant(route);
    }

    async validateTenant(route: any) {
        let paramTenant = route.queryParams.tenant;
        if (paramTenant && localStorage.getItem(STORAGE_KEYS.tenantId) === paramTenant) {
            return true;
        }
        if (route.queryParams.tenant) {
            paramTenant = atob(route.queryParams.tenant);
        }
        if (paramTenant) {
            const isValidTenant = await this.isValidTenant(paramTenant);
            if (isValidTenant) {
                localStorage.setItem(STORAGE_KEYS.tenantId, paramTenant);
                return true;
            } else {
                this.navigateToInvalidLink();
                return false;
            }
        } else {
            let sessionTenant: any;
            if (localStorage.getItem(STORAGE_KEYS.tenantId) !== null) {
                sessionTenant = localStorage.getItem(STORAGE_KEYS.tenantId);
            }
            if (sessionTenant) {
                const isValidTenant = await this.isValidTenant(sessionTenant);

                if (isValidTenant) {
                    return true;
                } else {
                    this.navigateToInvalidLink();
                    return false;
                }
            }
        }

        this.navigateToInvalidLink();
        return false;
    }

    async isValidTenant(tenantId: any) {
        return true;
    }

    navigateToInvalidLink() {
        this.router.navigate(['common-error/expired-link']);
    }
}
