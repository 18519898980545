import {AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CommonServiceService} from '../../services/common-service.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {SharedDataService} from '../../services/shared-data.service';
import {PartnerService} from '../../services/partner.service';

interface ChatHistory {
    chatData: string;
    originFrom: string;
    originName: string;
    initTime: string;
    isAttachment?: boolean;
    fileName?: string;
}

@Component({
    selector: 'app-live-chat',
    templateUrl: './live-chat.component.html',
    styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input() isPartner: boolean;
    @Input() userId: string;
    public chatHistoryData: ChatHistory[] = [];
    public ticketConvo: ChatHistory[] = [];
    liveChat = '';
    public tickets = [];
    public selectedTicket: any;
    public isTicketReady = false;
    public isTicketConvo = false;
    public customerName = '';
    public companyName = '';
    public customerEmail = '';
    public userName = '';
    public loggedUserId = '';
    public adminUserName = '';
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    @ViewChild('scrollMeTicket ') private myScrollContainerTicket: ElementRef;

    constructor(
        // private ticketService: TicketService,
        //         private reviewService: ReviewService,
        private commonService: CommonServiceService,
        private ngxService: NgxUiLoaderService,
        private partnerService: PartnerService,
        private sharedDataService: SharedDataService) {
    }

    ngOnInit(): void {
        this.chatHistoryData = [];
        this.sharedDataService.getUserData().subscribe(userData => {
            this.loggedUserId = userData.user_id;
            this.adminUserName = userData.first_name[0].toUpperCase() + userData.last_name[0].toUpperCase();

        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ticketConvo = [];
        this.chatHistoryData = [];
        this.isTicketConvo = false;
        this.isTicketReady = false;
        // if (!this.isPartner && this.userId) {
        //     this.ngxService.start();
        //     this.ticketService.reviewMgtGetAllTicketsIdGet(this.userId).subscribe(res => {
        //         if ( res !== null) {
        //             this.tickets = res.length > 0 ? res : [];
        //         } else {
        //             this.tickets = [];
        //         }
        //         this.customerName = '';
        //         this.companyName = '';
        //         this.customerEmail = '';
        //         this.partnerService.getSingleUserAsAdmin(this.userId).subscribe(response => {
        //             this.ngxService.stop();
        //             this.customerName = response.firstName + ' ' + response.lastName;
        //             this.userName = response.firstName[0].toUpperCase() + response.lastName[0].toUpperCase();
        //             const org = response.organizationDtoList[0];
        //             this.companyName = org !== undefined ? org.name : '';
        //             this.customerEmail = response.email;
        //             this.ticketService.reviewMgtGetAllCustomerTicketsIdGet(this.userId).subscribe(responses => {
        //                 this.ngxService.stop();
        //                 this.chatHistoryData = [];
        //                 if ( responses !== null) {
        //                     responses.forEach(rev => {
        //                         const userType = rev.userId === this.loggedUserId ? 'ADMIN' : 'OTHER';
        //                         const name = rev.userId === this.loggedUserId ? this.adminUserName : this.userName;
        //                         this.chatHistoryData.push(
        //                             {chatData: rev.review, originFrom: userType, originName: name, initTime: ''}
        //                         );
        //                     });
        //                 }
        //             }, error => {
        //                 this.ngxService.stop();
        //                 this.commonService.snackBarErrorsMessage('Error loading chat');
        //             });
        //         }, error => {
        //             this.ngxService.stop();
        //             this.commonService.snackBarErrorsMessage('Error getting user details');
        //         });
        //     }, error => {
        //         this.ngxService.stop();
        //         this.commonService.snackBarErrorsMessage('Error occured while retrieving customer tickets');
        //         this.tickets = [];
        //     });
        // }
        // if (this.isPartner && this.userId) {
        //     this.ngxService.start();
        //     this.partnerService.getSingleUserAsAdmin(this.userId).subscribe(response => {
        //         this.userName = response.firstName[0].toUpperCase() + response.lastName[0].toUpperCase();
        //         this.ticketService.reviewMgtGetAllPartnerTicketsIdGet(this.userId).subscribe(responses => {
        //             this.ngxService.stop();
        //             this.chatHistoryData = [];
        //             if ( responses !== null) {
        //                 responses.forEach(rev => {
        //                     const userType = rev.userId === this.loggedUserId ? 'ADMIN' : 'OTHER';
        //                     const name = rev.userId === this.loggedUserId ? this.adminUserName : this.userName;
        //                     this.chatHistoryData.push(
        //                         {chatData: rev.review, originFrom: userType, originName: name, initTime: ''}
        //                     );
        //                 });
        //             }
        //         }, error => {
        //             this.ngxService.stop();
        //             this.commonService.snackBarErrorsMessage('Error loading chat');
        //         });
        //     }, error => {
        //         this.ngxService.stop();
        //         this.commonService.snackBarErrorsMessage('Error getting user details');
        //     });
        // }
    }

    sendChat(isCommonthread: boolean) {
        // const today = new Date();
        // const currentTime = ('0' + today.getHours().toString()).slice(-2) + ':' + ('0' + today.getMinutes().toString()).slice(-2);
        // if (this.liveChat) {
        //     if (!!isCommonthread) {
        //         this.chatHistoryData.push(
        //             {chatData: this.liveChat, originFrom: 'ADMIN', originName: this.adminUserName, initTime: currentTime}
        //         );
        //     } else {
        //         this.ticketConvo.push(
        //             {chatData: this.liveChat, originFrom: 'ADMIN', originName: this.adminUserName, initTime: currentTime}
        //         );
        //     }
        //     const chatDetail: ModelsTicketReq = {
        //         review: this.liveChat,
        //         userId: this.loggedUserId,
        //         category: this.isPartner ? 'Partner' : 'Customer',
        //         ticketId: isCommonthread ? '' : this.selectedTicket.ticketID,
        //         productId: '',
        //         partnerId: this.userId
        //     }
        //     this.ticketService.reviewMgtTicketPost(chatDetail).subscribe(res => {
        //     }, error => {
        //         this.commonService.snackBarErrorsMessage('An error has occurred while sending the chat message');
        //     });
        // }
        // this.liveChat = '';
    }

    ngAfterViewChecked() {
        this.scrollToBottom();
        this.scrollTicketToBottom();
    }

    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) {
        }
    }

    scrollTicketToBottom(): void {
        try {
            this.myScrollContainerTicket.nativeElement.scrollTop = this.myScrollContainerTicket.nativeElement.scrollHeight;
        } catch (err) {
        }
    }

    getTicketData(id: number) {
        // this.selectedTicket = this.tickets.find(res => res.id === id);
        // this.ngxService.start();
        // this.ticketService.reviewMgtGetAllTicketsByIdIdGet(this.selectedTicket.ticketID).subscribe(res => {
        //     this.ngxService.stop();
        //     this.ticketConvo = [];
        //     res.forEach(rev => {
        //         const userType = rev.userId === this.loggedUserId ? 'ADMIN' : 'OTHER';
        //         const name = rev.userId === this.loggedUserId ? this.adminUserName : this.userName;
        //         this.ticketConvo.push(
        //             {chatData: rev.review, originFrom: userType, originName: name, initTime: ''}
        //         );
        //     });
        //     this.isTicketReady = true;
        // }, error => {
        //     this.ngxService.stop();
        //     console.log(error.error.errorDesc);
        // });
        // this.isTicketReady = true;
    }

    getTicketConvo() {
        this.isTicketConvo = !this.isTicketConvo;
    }
}
