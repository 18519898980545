// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholder {
  background-color: #fff;
  border-bottom: 1px solid #fbfbfb;
  display: flex;
  height: 48px;
  margin: 0 auto;
  width: 100%;
}

.animated-background {
  flex: 1;
  width: 200px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #f9f9f9 10%, #f0f0f0 18%, #f9f9f9 33%);
  background-size: auto;
  background-size: auto;
  background-size: 800px 104px;
  border: 5px solid #fff;
  border-radius: 10px;
  height: 50%;
  margin: 6px 10px 6px 10px;
  position: relative;
}

.image-holder {
  height: 50px;
  margin: -6px 0 0 20px;
  max-width: 50px;
}

.description {
  min-width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/table-loader/table-loader.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,gCAAA;EACA,aAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;AACF;;AAEA;EACE,OAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,6BAAA;EACF;EACA;IACE,4BAAA;EACF;AACF;AAEA;EACE,sBAAA;EACA,6BAAA;EACA,mCAAA;EACA,kCAAA;EACA,iCAAA;EACA,oBAAA;EACA,4EAAA;EACA,qBAAA;EACA,qBAAA;EACA,4BAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,YAAA;EACA,qBAAA;EACA,eAAA;AAAF;;AAGA;EACE,cAAA;AAAF","sourcesContent":[".placeholder {\n  background-color: #fff;\n  border-bottom: 1px solid #fbfbfb;\n  display: flex;\n  height: 48px;\n  margin: 0 auto;\n  width: 100%;\n}\n\n.animated-background {\n  flex: 1;\n  width: 200px;\n}\n\n@keyframes placeHolderShimmer {\n  0% {\n    background-position: -468px 0\n  }\n  100% {\n    background-position: 468px 0\n  }\n}\n\n.animated-background {\n  animation-duration: 3s;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-name: placeHolderShimmer;\n  animation-timing-function: linear;\n  background: darkgray;\n  background: linear-gradient(to right, #f9f9f9 10%, #f0f0f0 18%, #f9f9f9 33%);\n  background-size: auto;\n  background-size: auto;\n  background-size: 800px 104px;\n  border: 5px solid #fff;\n  border-radius: 10px;\n  height: 50%;\n  margin: 6px 10px 6px 10px;\n  position: relative;\n}\n\n.image-holder {\n  height: 50px;\n  margin: -6px 0 0 20px;\n  max-width: 50px;\n}\n\n.description {\n  min-width: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
