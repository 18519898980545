// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* footer component css */

.footer {
  bottom: 0;
  position: fixed;
  margin-bottom: 0px;
  width: 260px;
  background: #e4e7ed;
  padding: 5px 0;
  height: 55px;
}

.footer p {
  font-size: 11px;
  margin: 15px 0px 0px 0px;
  line-height: 15px;
  color: #58595b;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;EACE,SAAS;EACT,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":["/* footer component css */\n\n.footer {\n  bottom: 0;\n  position: fixed;\n  margin-bottom: 0px;\n  width: 260px;\n  background: #e4e7ed;\n  padding: 5px 0;\n  height: 55px;\n}\n\n.footer p {\n  font-size: 11px;\n  margin: 15px 0px 0px 0px;\n  line-height: 15px;\n  color: #58595b;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
