import {AbstractControl} from '@angular/forms';
import {CONFIG_FILE_TYPES, DOCUMENT_FILE_TYPES, FILE_TYPES, IMAGE_TYPES, SWAGGER_FILE_TYPES,} from '../constants/common-constants';

export function ValidateUploadFile(
    control: AbstractControl
): { [key: string]: boolean } | null {
    return checkFileTypes(control, IMAGE_TYPES);
}

export function ValidateZipUploadFile(
    control: AbstractControl
): { [key: string]: boolean } | null {
    return checkFileTypes(control, FILE_TYPES);
}

export function ValidateSwaggerUploadFile(
    control: AbstractControl
): { [key: string]: boolean } | null {
    return checkFileTypes(control, SWAGGER_FILE_TYPES);
}

export function ValidateConfigUploadFile(
    control: AbstractControl
): { [key: string]: boolean } | null {
    return checkFileTypes(control, CONFIG_FILE_TYPES);
}

export function ValidateDocumentUploadFile(
    control: AbstractControl
): { [key: string]: boolean } | null {
    return checkFileTypes(control, DOCUMENT_FILE_TYPES);
}

function checkFileTypes(control: AbstractControl, fileTypes: any[]) {
    try {
        if (!control.value) {
            return null;
        }
        if (control.value.length === 0) {
            return null;
        }
        const name = control.value.fileNames;
        const ext = name.substring(name.lastIndexOf('.') + 1);
        if (name.includes('.') && fileTypes.includes('.' + ext)) {
            return null;
        } else if (!name.includes('.')) {
            return null;
        } else {
            return {invalidateFile: true};
        }
    } catch (e) {
        return {invalidateFile: true};
    }
}
