import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../services/auth.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SharedDataService} from '../../services/shared-data.service';
import {CommonServiceService} from '../../services/common-service.service';
import {USER_ROUTES} from '../../constants/common-constants';

export interface DialogData {
    entityList: [];
    defaultEntityId: number;
    defaultEntityType: string;
    showCloseBtn: boolean;
}

@Component({
    selector: 'app-entity-selection',
    templateUrl: './entity-selection.component.html',
    styleUrls: ['./entity-selection.component.scss']
})
export class EntitySelectionComponent implements OnInit {

    entityListData = [];
    entityName: string;
    defaultEntityId: number;
    entitySelectionForm: FormGroup;
    showSwitch = false;
    showCloseBtn: boolean;
    isSelected = false;

    constructor(
        public dialogRef: MatDialogRef<EntitySelectionComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private authService: AuthService,
        private ngxService: NgxUiLoaderService,
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private _formBuilder: FormBuilder,
        private shareDataService: SharedDataService,
        private commonService: CommonServiceService
    ) {
    }

    ngOnInit() {
        if (this.data != null) {
            this.showCloseBtn = this.data.showCloseBtn;
            this.entityListData = this.data.entityList;
            this.defaultEntityId = this.data.defaultEntityId;
            if (this.defaultEntityId !== undefined) {
                this.isSelected = true;
            }
            if (this.data.defaultEntityType === 'TENANT') {
                this.entityName = 'DEPARTMENT';
            }
            if (this.data.defaultEntityType === 'DIVISION') {
                this.entityName = 'DEPARTMENT';
            }
            if (this.data.defaultEntityType === 'PLATFORM') {
                this.entityName = 'TENANT';
            }
        }

        this.entitySelectionForm = this._formBuilder.group({
            selectedEntityId: new FormControl('', []),
            isDefault: new FormControl('true', []),
        });
    }

    getUserAllEntity(entityId: any, isDefault: any) {
        this.ngxService.start();
        this.authService.getEntitySelectionToken(entityId, isDefault)
            .subscribe(res => {
                if (res) {
                    this.ngxService.stop();
                    const decodeToken = this.shareDataService.getDecodedToken();
                    // const perm = decodeToken.entities[0].permissions;
                    const perm: any = JSON.parse(sessionStorage.getItem('permissions') ?? '');
                    this.permissionsService.loadPermissions(perm);
                    this.ngxService.stop();
                    if (perm.includes('DASHBOARD_VIEW')) {
                        // this.router.navigate(['/secure/dashboard']);
                        this.router.navigate([USER_ROUTES.USER_MGT]);
                    } else if (!perm.includes('DASHBOARD_VIEW') && perm.includes('PROJECT_VIEW')) {
                        this.router.navigate(['/projects']);
                    } else if (!perm.includes('DASHBOARD_VIEW') && perm.includes('MONITORING_VIEW')) {
                        this.router.navigate(['/monitoring']);
                    }
                    this.dialogRef.close();
                    location.reload();
                }
                this.ngxService.stop();
            }, err => {
                this.ngxService.stop();
                this.commonService.snackBarErrorsMessage(err.error.errorDesc);
            });
    }

    selectDefault(data: any, defaultEntityId: any) {
        this.getUserAllEntity(defaultEntityId, data.isDefault);
    }

    changeSelectedEntity(event: any, selected: any) {
        this.isSelected = event.checked;
        this.defaultEntityId = selected;
        this.showSwitch = true;
    }

}
