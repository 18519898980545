// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-element-loader-holder {
  position: absolute;
  right: 77px;
  top: 6px;
  z-index: 9999;
}
.form-element-loader-holder .spinner {
  background: #fafafa !important;
  display: inline-block;
  font-size: 10px;
  height: 60px;
  text-align: center;
  transform: rotate(90deg);
  width: 20px;
}
.form-element-loader-holder .spinner > div {
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation-delay: 0s;
  background-color: #e1e1e1;
  display: inline-block;
  height: 100%;
  margin: 0 2px 0 0;
  width: 3px;
}
.form-element-loader-holder .spinner .rect2 {
  animation-delay: -1.1s;
}
.form-element-loader-holder .spinner .rect3 {
  animation-delay: -1s;
}
.form-element-loader-holder .spinner .rect4 {
  animation-delay: -0.9s;
}
.form-element-loader-holder .spinner .rect5 {
  animation-delay: -0.8s;
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/form-element-list-loader/form-element-list-loader.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,aAAA;AACF;AAEE;EACE,8BAAA;EACA,qBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,wBAAA;EACA,WAAA;AAAJ;AAGE;EAEE,oDAAA;EACA,mBAAA;EACA,yBAAA;EACA,qBAAA;EACA,YAAA;EACA,iBAAA;EACA,UAAA;AADJ;AAIE;EAEE,sBAAA;AAFJ;AAKE;EAEE,oBAAA;AAHJ;AAME;EAEE,sBAAA;AAJJ;AAOE;EAEE,sBAAA;AALJ;AAkBA;EACE;IACE,sBAAA;IACA,8BAAA;EAPF;EASA;IACE,oBAAA;IACA,4BAAA;EAPF;AACF","sourcesContent":[".form-element-loader-holder {\n  position: absolute;\n  right: 77px;\n  top: 6px;\n  z-index: 9999;\n\n\n  .spinner {\n    background: #fafafa !important;\n    display: inline-block;\n    font-size: 10px;\n    height: 60px;\n    text-align: center;\n    transform: rotate(90deg);\n    width: 20px;\n  }\n\n  .spinner > div {\n    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;\n    animation: sk-stretchdelay 1.2s infinite ease-in-out;\n    animation-delay: 0s;\n    background-color: #e1e1e1;\n    display: inline-block;\n    height: 100%;\n    margin: 0 2px 0 0;\n    width: 3px;\n  }\n\n  .spinner .rect2 {\n    -webkit-animation-delay: -1.1s;\n    animation-delay: -1.1s;\n  }\n\n  .spinner .rect3 {\n    -webkit-animation-delay: -1.0s;\n    animation-delay: -1.0s;\n  }\n\n  .spinner .rect4 {\n    -webkit-animation-delay: -0.9s;\n    animation-delay: -0.9s;\n  }\n\n  .spinner .rect5 {\n    -webkit-animation-delay: -0.8s;\n    animation-delay: -0.8s;\n  }\n}\n\n@-webkit-keyframes sk-stretchdelay {\n  0%, 40%, 100% {\n    -webkit-transform: scaleY(0.4)\n  }\n  20% {\n    -webkit-transform: scaleY(1.0)\n  }\n}\n\n@keyframes sk-stretchdelay {\n  0%, 40%, 100% {\n    transform: scaleY(0.4);\n    -webkit-transform: scaleY(0.4);\n  }\n  20% {\n    transform: scaleY(1.0);\n    -webkit-transform: scaleY(1.0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
