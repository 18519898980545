import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonServiceService} from '../../services/common-service.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SharedDataService} from '../../services/shared-data.service';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {SettingsService} from 'app/settings/services/settings.service';
import {USER_ROUTES} from '../../constants/common-constants';

declare const $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    location: Location;
    public mobile_menu_visible: any = 0;
    public notifications: any;
    public userId: any;
    public pageId: any;
    public marketplaceUrl: any;
    USER_ROUTES = USER_ROUTES;
    id: any;
    @Input('menuName') menuName: string;
    navHeaderName = '';
    subNavHeaderName: any = null;
    searchText = '';
    searchForm: FormGroup;
    fullName: any;
    switchName = '';
    entityCountData = 0;
    entityName: any;
    subscription: Subscription;
    collapseData = false;

    constructor(
        location: Location,
        private router: Router,
        private commonService: CommonServiceService,
        private route: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private sharedData: SharedDataService,
        public dialog: MatDialog,
        private settingsService: SettingsService,
    ) {
        this.location = location;

        this.route.data.subscribe(data => {
            this.navHeaderName = data.breadcrumbs;
        });
    }

    ngOnInit() {
        this.getMarketplaceUrl();
        this.searchForm = this._formBuilder.group({
            searchText: ['', []],
        });
        this.router.events.subscribe((_) => {
            this.sidebarClose();
            const $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });
        this.sharedData.getUserData().subscribe(user => {
            this.userId = user.user_id;
            this.pageId = 0;
            this.notifications = [];

            this.commonService.currentMessage.subscribe(message => {
                this.subNavHeaderName = message;
            })

            // this.authService.getUserEntityList()
            //     .subscribe(res => {
            //         this.entityCountData = res.length
            //     }, err => {
            //         this.commonService.snackBarErrorsMessage(err.error.errorDesc);
            //     });
            const userDetails = {
                'firstName': user.first_name,
                'lastName': user.last_name
            }
            // this.getUserSummaryInfo(user.user_id);
            if (user.user_type === 'PLATFORM') {
                this.switchName = 'Tenant';
            } else {
                this.switchName = 'Department';
            }
            // this.showDeptSwitchPanel();
            this.fullName = userDetails;
            // $('.search-icon').hover(function () {
            //     $('.form-control.search-input').show();
            // });
        }, error => {
            console.error('Error fetching user data', error);
        });
    }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }

    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('nav-open');
    };

    logout() {
        this.sharedData.logout();
    }

    searchByText(data: any) {
        this.commonService.searchText(data.searchText)
    }

    switchTenant() {
        this.commonService.getUserAllEntity();
    }

    getMarketplaceUrl() {
        this.settingsService.getConfigurationByCategoryFromSMS('GENERAL').subscribe(
            (res) => {
                if (!!res) {
                    res.forEach((value: any, index: any) => {
                        if (value.name === 'MARKET_PLACE_URL') {
                            this.marketplaceUrl = value.value;
                        }

                    });
                }
            }
        )
    }

    collapse() {
        this.collapseData = (!this.collapseData);
        this.commonService.collapseMenu(this.collapseData)
    }
}
