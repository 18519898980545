import {Component, OnInit} from '@angular/core';
import {ConfigServiceService} from '../../services/config-service.service';
import {SettingsService} from '../../settings/services/settings.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    appVersion: string;
    copyRightText: string = '';

    constructor(private configService: ConfigServiceService,
                private settingsService: SettingsService) {

    }

    ngOnInit() {
        this.appVersion = this.configService.appVersion;
        this.copyRightText = sessionStorage.getItem('copyRightText') ?? '';
    }
}
