import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {switchMap, tap} from 'rxjs/operators';
import {User} from '../../models/User';
import {DisabledUser} from '../../models/DisabledUser';


const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly UMS_API: string | undefined;
    private readonly DMS_URL: string | undefined;
    private readonly SPM_URL: string | undefined;

    private _userDetails: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);


    constructor(private http: HttpClient) {

        // this.UMS_API = this.configService.umsUrl;
        // this.DMS_URL = ConfigServiceService.dmsUrl;
        // this.SPM_URL = this.configService.spmUrl;
    }

    public getUserAll(): Observable<User[]> {
        const url = this.UMS_API + '/user-mgt/users';
        return this.http
            .get<User[]>(url).pipe(
                tap(users => console.log('fetched users')),
            )
    }

    public getUserById(id: number): Observable<User> {
        const url = this.UMS_API + '/user-mgt/users/' + id;
        return this.http
            .get<User>(url).pipe(
                tap(user => console.log('fetched user')),
            )
    }

    public getUserSummaryInfo(userId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/summary';
        return this.http
            .get<User>(url).pipe(
                tap(user => console.log('fetched user summary info')),
            )
    }

    public getUserByIdAndEntityType(id: number, requiredEntityType: string): Observable<User> {
        const url = this.UMS_API + '/user-mgt/users/' + id + '?requiredEntityType=' + requiredEntityType;
        return this.http
            .get<User>(url).pipe(
                tap(user => console.log('fetched user')),
            )
    }

    public addUser(user: any): Observable<User> {
        const url = this.UMS_API + '/user-mgt/users';
        return this.http.post<User>(url, user, httpOptions).pipe(
            tap((application: User) => console.log('added user')),
        );
    }

    public getUsers(): Observable<User[]> {
        // const url = this.UMS_API + '/user-mgt/users';
        // return this.http
        //     .get<any[]>(url).pipe(
        //         tap(users => console.log('fetched users')),
        //     )
        return of([]);

    }

    public getUserTypeList(): Observable<any[]> {
        const url = this.UMS_API + '/user-mgt/user-types/assignable';
        return this.http.get<any[]>(url).pipe(
            tap((userTypeList: any[]) => console.log('fetched tenent list ')),
        );
    }

    public getEntitybyEntityType(entityType: string): Observable<any> {
        const url = this.DMS_URL + '/deployment-mgt/entities/assignable?entityTypeName=' + entityType;
        return this.http.get<any[]>(url).pipe(
            tap((deptList: any[]) => console.log('fetched tenent list ')),
        );
    }

    public getUserGroupList(userType: string): Observable<any[]> {
        // let url = this.UMS_API + '/user-mgt/groups';
        // if (userType != null) {
        //     url = url + '?requiredGroupType=' + userType;
        // }
        // return this.http.get<any[]>(url).pipe(
        //     tap((userGroupList: any[]) => console.log('fetched tenent list ')),
        // );
        return of([]);

    }

    public getPermissionList(userType: any): Observable<any[]> {
        let url = this.UMS_API + '/user-mgt/permissions';
        if (userType != null) {
            url = url + '?requiredPermissionType=' + userType;
        }
        return this.http.get<any[]>(url).pipe(
            tap((permissionList: any[]) => console.log('fetched permissions ')),
        );
    }

    public resetPassword(data: any, id: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + id + '/reset-password';
        return this.http.put<any>(url, data, httpOptions).pipe(
            tap((resetPassword: any) => console.log('reset password')),
        );
    }


    public deleteUserById(id: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + id;
        return this.http.delete(url, httpOptions).pipe(
            tap((deleteUser: any) => console.log('delete user')),
        );
    }

    public submitUserGroups(userId: number, selectedIds: any): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/groups'
        return this.http.put(url, selectedIds, httpOptions).pipe(
            tap(_ => console.log(`updated user groups`)),
        );
    }

    public submitUserPermissions(userId: number, entityId: number, selectedIds: any): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/permissions'
        console.log(url);
        return this.http.put(url, selectedIds, httpOptions).pipe(
            tap(_ => console.log(`updated permissions`)),
        );

    }

    public deleteGroup(groupId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/groups/' + groupId;
        return this.http
            .delete<any>(url).pipe(
                tap(_ => console.log(`deleted group`)),
            );
    }

    public createGroup(data: any): Observable<any> {
        const url = this.UMS_API + '/user-mgt/groups';
        return this.http.post(url, data, httpOptions).pipe(
            tap(_ => console.log(`updated user groups`)),
        );
    }

    public getGroupData(groupId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/groups/' + groupId;
        return this.http
            .get<any>(url).pipe(
                tap(users => console.log('fetched group details')),
            )
    }

    public removeGroupFromUser(userId: number, groupId: number, entityId: number): Observable<any> {
        const url = this.UMS_API + '/users/remove-group/' + userId + '/' + groupId + '/' + entityId;
        return this.http.delete(url, httpOptions).pipe(
            tap(_ => console.log(`deleted group`)),
        );
    }

    public updateGroup(data: any, id: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/groups/' + id;
        return this.http.put(url, data, httpOptions).pipe(
            tap(_ => console.log(`updated user groups`)),
        );
    }

    public editBasicUserDetails(id: number, entity: any): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + id;
        return this.http.put(url, entity, httpOptions).pipe(
            tap(_ => console.log(`updated entity id=${id}`)),
        );
    }

    public getUserListByEntity(entityId: number, usertype: string): Observable<any> {
        const url = this.UMS_API + '/user-mgt/entities/' + entityId + '/users?userState=ACTIVE&userType=' + usertype;
        return this.http
            .get<any>(url).pipe(
                tap(users => console.log('fetched group details')),
            )
    }

    public getUserRoleList(): Observable<any> {
        const url = this.SPM_URL + '/plugin-mgt/access-levels';
        return this.http
            .get<any>(url).pipe(
                tap(users => console.log('fetched user role details')),
            )
    }

    public AddUserstoGroups(groupId: number, selectedIds: any): Observable<any> {
        const url = this.UMS_API + '/user-mgt/groups/' + groupId + '/users'
        return this.http.put(url, selectedIds, httpOptions).pipe(
            tap(_ => console.log(`updated user groups`)),
        );
    }

    public getGroupAssignableUsers(groupId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/groups/' + groupId + '/users/assignable'
        return this.http.get<any>(url).pipe(
            tap(users => console.log('fetched group assignable users')),
        )
    }

    public editUserPermissionsGroups(data: string, id: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + id + '/entitlements';
        return this.http.put<any>(url, data, httpOptions).pipe(
            tap((editUserPermissionsGroups: any) => console.log('edit user')),
        );
    }

    public resendVerification(userId: number, loginApiType: string): Observable<User> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/resend-login-email?loginApiType=' + loginApiType;
        return this.http.post<User>(url, httpOptions).pipe(
            tap((application: User) => console.log('added user')),
        );
    }

    public disableUser(data: any, userId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/disable';
        return this.http.post(url, data, httpOptions).pipe(
            tap(_ => console.log(`user disabled`)),
        );
    }

    public activateDisabledUser(userId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/activate';
        return this.http.post(url, httpOptions).pipe(
            tap(_ => console.log(`user activated`)),
        );
    }

    public activateInactivatedUser(userId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/re-activate';
        return this.http.post(url, httpOptions).pipe(
            tap(_ => console.log(`user reactivated`)),
        );
    }

    public unlockUser(userId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/unlock';
        return this.http.post(url, httpOptions).pipe(
            tap(_ => console.log(`user unlocked`)),
        );
    }

    public getDisabledUserById(userId: number): Observable<DisabledUser> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/disable';
        return this.http.get<DisabledUser>(url).pipe(
            tap(disableUser => console.log('fetched disabledUser')),
        )
    }

    public getUserListByUserType(entityId: number, userType: string): Observable<any> {
        const url = this.UMS_API + '/user-mgt/entities/' + entityId + '/' + userType + '/users';
        return this.http.get<any>(url).pipe(
            tap(users => console.log('fetched user details by user type')),
        )
    }

    public getEntityIdListByUserIdAndEntityTypeId(userId: number, entityTypeId: number): Observable<any> {
        const url = this.UMS_API + '/user-mgt/users/' + userId + '/entities?entityTypeId=' + entityTypeId;
        return this.http.get<any>(url).pipe(
            tap(entities => console.log('fetched entity id list by user id and entity type id')),
        )
    }

    public getEligibleSupportUsers(entityId: number, userType: string, userState: string, permissions: any): Observable<any> {
        const url = this.UMS_API + '/user-mgt/entities/' + entityId + '/authorized-users'
            + '?userType=' + userType + '&userState=' + userState + '&permissionList=' + permissions;
        return this.http.get<any>(url).pipe(
            tap(users => console.log('fetched user details by permissions')),
        )
    }

    public getUserDetails(token: string): Observable<any> {
        const url = this.UMS_API + '/user-mgt/user/get_user_details';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        });

        return this._userDetails.pipe(switchMap(userDetails => {
            if (userDetails) {
                return of(userDetails);
            }

            return this.http.get<any>(url, {headers: headers}).pipe(
                tap((getUserDetails: any) => {
                    console.log('Get User Details');
                    this._userDetails.next(getUserDetails);
                }),
            );
        }));
    }
}

