import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'secMinHour'
})
export class SecMinHourPipe implements PipeTransform {

    transform(ms: any): any {
        let m = 0;
        let h = 0;
        let d = 0;
        let s = 0;
        const cd = 24 * 60 * 60 * 1000;
        const ch = 60 * 60 * 1000;
        d = Math.floor(ms / cd);
        h = Math.floor((ms - d * cd) / ch);
        m = Math.floor((ms - d * cd - h * ch) / 60000);
        s = Math.round((ms - d * cd - h * ch - m * 60000) / 1000);

        if (m === 60) {
            h++;
            m = 0;
        }
        if (h === 24) {
            d++;
            h = 0;
        }
        const seconds = parseInt(s.toString(), 10);
        const minutes = parseInt(m.toString(), 10);
        const hours = parseInt(h.toString(), 10);
        const days = parseInt(d.toString(), 10);
        const showDays = days > 0 ? days + 'd ' : '';
        const showHours = hours > 0 ? hours + 'h ' : '';
        const showMinutes = minutes > 0 ? minutes + 'm ' : '';

        return showDays + showHours + showMinutes + seconds + 's';
    }

}
