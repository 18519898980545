import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-show-image-on-hover',
    templateUrl: './show-image-on-hover.component.html',
    styleUrls: ['./show-image-on-hover.component.scss']
})
export class ShowImageOnHoverComponent implements OnInit {
    @Input() imageUrl: string = '';
    @Input() imagePropertyKey: string;
    @Input() clickable = true;
    @Input() allowFullScreen = false;
    @Input() images: any[] = [];
    showPreviewDialog = false;
    activeIndex = 0;

    ngOnInit() {
        if (this.images.length > 0) {
            this.activeIndex = this.imagePropertyKey ? this.images.findIndex((element) => element[this.imagePropertyKey] === this.imageUrl) : this.images.findIndex((element) => element === this.imageUrl);
        } else {
            if (this.imagePropertyKey) {
                const passing_object: any = {};
                passing_object[this.imagePropertyKey] = this.imageUrl;
                this.images.push(passing_object);
            } else {
                this.images.push(this.imageUrl)
            }
            this.activeIndex = 0;
        }
    }

}
