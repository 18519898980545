import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'any'
})
export class CommonService {
    private messageSource = new BehaviorSubject('');
    currentMessage = this.messageSource.asObservable();

    private searchSource = new BehaviorSubject('');
    currentSearch = this.searchSource.asObservable();

    private entitySource = new BehaviorSubject('');
    currentEntity = this.entitySource.asObservable();

    private toggleSource = new BehaviorSubject(false);
    currentCollapse = this.toggleSource.asObservable();

    private tempData: any;

    constructor(private snackBar: MatSnackBar) {
    }

    changeMessage(message: string) {
        this.messageSource.next(message);
    }

    searchText(search: string) {
        this.searchSource.next(search);
    }

    collapseMenu(collapse: boolean) {
        this.toggleSource.next(collapse);
    }

    snackBarSuccessMessage(message: string) {
        this.snackBar.open(message, '', {
                duration: 5000,
                panelClass: ['success-snackbar']
            }
        );
    }

    snackBarErrorsMessage(message: string) {
        if (message !== undefined) {
            this.snackBar.open(message, '', {
                    duration: 5000,
                    panelClass: ['failed-snackbar']
                }
            );
        }
    }

    snackBarWarningMessage(message: string) {
        this.snackBar.open(message, '', {
                duration: 5000,
                panelClass: ['warning-snackbar']
            }
        );
    }

    titleCaseWord(word: string) {
        if (!word) {
            return word;
        }
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }

    setData(data: any) {
        this.tempData = data;
    }

    getTemplateData() {
        return this.tempData;
    }
}
