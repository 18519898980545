import {Injectable} from '@angular/core';
import {STORAGE_KEYS} from '../shared/constants';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    tenantId: any = '';

    constructor() {
    }

    fetchTheme() {
        const url = new URL(window.location.href);
        const encodedTenantId: any = url.searchParams.get('tenant');
        this.tenantId = atob(encodedTenantId);
        if (encodedTenantId) {
            localStorage.setItem(STORAGE_KEYS.tenantId, this.tenantId);
        } else {
            this.tenantId = localStorage.getItem(STORAGE_KEYS.tenantId);
        }
    }
}
