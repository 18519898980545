import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-table-loader',
    templateUrl: './table-loader.component.html',
    styleUrls: ['./table-loader.component.scss']
})
export class TableLoaderComponent implements OnInit {
    displayedColumns: string[] = ['image', 'name']; // Add other columns as needed
    dataSource: any[] = [];

    constructor() {
    }

    ngOnInit(): void {
        this.dataSource = [
            {image: 'path/to/image1.jpg', name: 'Item 1'},
            {image: 'path/to/image2.jpg', name: 'Item 2'}
            // Add actual data here
        ];
    }

    showLoadingPlaceholders() {
        this.dataSource = Array(5).fill({image: null, name: null});
    }

}
