import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

interface TreeNode {
    id: string;
    name: string;
    description: string;
    can_add_products: boolean;
    is_visible: boolean;
    parent_id: string;
    parent_category: string;
    tenant_id: string;
    template_id: string;
    design_template_id: string;
    primary_image_url: string;
    thumbnail_image_url: string;
    created_at: string;
    children?: TreeNode[];
}

@Component({
    selector: 'app-category-tree',
    templateUrl: './category-tree.component.html',
    styleUrls: ['./category-tree.component.scss']
})
export class CategoryTreeComponent implements OnChanges {
    @Input() treeData: any;
    @Input() menuMode = false;
    @Input() isMulti = true;
    @Input() selected: any;
    @Output() onSelectEvent = new EventEmitter();
    toggle: any = {};
    selectedItems: any[] = [];
    selectedItem: any;
    breadcrumbs: any[] = [];
    allSelected: boolean = false;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selected) {
            if (this.isMulti) {
                this.selectedItems = this.selected ? this.selected : undefined;
            } else {
                this.selectedItem = this.selected;
                this.breadcrumbs = this.findParentBreadcrumbs(this.selectedItem.id);
            }
        }
        if (this.treeData) {
            this.toggle = this.treeData.map((i: any) => true);
        }
    }

    checkCategory(category: any) {
        if (this.isMulti) {
            return this.selectedItems.length > 0 && this.selectedItems.find(cat => cat.id === category.id);
        } else {
            return this.selectedItem?.id === category.id;
        }
    }

    addCategory(category: any) {
        if (this.isMulti) {
            if (!this.selectedItems.find(cat => cat.id === category.id)) {
                this.selectedItems.push(category)
                this.onSelectEvent.emit(this.selectedItems);
            } else {
                const index = this.selectedItems.indexOf(category, 0);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                    this.onSelectEvent.emit(this.selectedItems);
                }
            }
        } else {
            this.selectedItem = category;
            this.onSelectEvent.emit(this.selectedItem);
            this.breadcrumbs = this.findParentBreadcrumbs(category.id);
        }
    }

    getWidth(element: HTMLElement) {

        return `${element.clientWidth}px`;
    }

    findParentBreadcrumbs(nodeId: string): TreeNode[] {
        const breadcrumbs: TreeNode[] = [];
        this.findNodeAndParents(this.treeData, nodeId, breadcrumbs);
        return breadcrumbs.reverse(); // Reverse the array to get breadcrumbs from root to node
    }

    selectAllItems(): void {
        if (this.allSelected) {
            this.selectedItems = [];
        } else {
            this.selectedItems = [];
            this.selectAllRecursive(this.treeData);
        }
        this.allSelected = !this.allSelected;
        this.onSelectEvent.emit(this.selectedItems);
    }

    selectAllRecursive(items: any[]): void {
        for (let item of items) {
            if (item.children && item.children.length) {
                this.selectAllRecursive(item.children);
            }
            this.selectedItems.push(item);
        }
    }

    // Helper function to find node and collect its parents
    private findNodeAndParents(nodes: TreeNode[], nodeId: string, breadcrumbs: TreeNode[]): boolean {
        for (const node of nodes) {
            if (node.id === nodeId) {
                breadcrumbs.push(node);
                return true;
            }
            if (node.children) {
                if (this.findNodeAndParents(node.children, nodeId, breadcrumbs)) {
                    breadcrumbs.push(node);
                    return true;
                }
            }
        }
        return false;
    }
}
