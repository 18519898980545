// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    basePath: 'http://localhost:4200/',
    dmsUrl: 'http://10.1.2.3:30082',
    umsUrl: 'http://10.1.2.3:30083',
    omsUrl: 'http://10.1.2.3:30084',
    spmUrl: 'http://10.1.2.3:30086',
    imsUrl: 'http://10.1.2.3:30081',
    amsUrl: 'http://10.1.2.3:30095',
    tmsUrl: 'http://10.1.2.3:30089',
    tmsSock: 'http://10.0.2.2:8088/tms-ws',
    manageServiceUrl: 'http://10.1.4.7/#/auth/ext',
    appVersion: '1.5.5',
    tmsReportUrl: 'http://10.0.2.2:8093',
    fileScanUrl: 'http://localhost:8080',
    loginToken: 'bWFya2V0cGxhY2UtYWRtaW4tcG9ydGFsOkQpXno3NXFZQmU='
};
