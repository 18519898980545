// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-heading {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.21;
  margin: 10px 0 20px 0;
  text-align: center;
}

.no-shadow-card {
  border: 1px solid #58595b;
  border-radius: 8px;
  box-shadow: none;
  padding: 12px;
  text-align: center;
}
.no-shadow-card h5 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
.no-shadow-card p {
  line-height: 1.18;
  text-align: center;
}
.no-shadow-card .mat-checkbox {
  border: 6px;
  left: -8px;
  position: absolute;
  top: -5px;
}

.no-shadow-card:hover {
  transform: scale(1.02);
}`, "",{"version":3,"sources":["webpack://./src/app/auth/entity-selection/entity-selection.component.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;AADF;;AAIA;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;AADF;AAGE;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAIE;EACE,iBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,WAAA;EACA,UAAA;EACA,kBAAA;EACA,SAAA;AAHJ;;AAOA;EACE,sBAAA;AAJF","sourcesContent":["@import \"../../../assets/variables/colors.scss\";\n\n.main-heading {\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 1.21;\n  margin: 10px 0 20px 0;\n  text-align: center;\n}\n\n.no-shadow-card {\n  border: 1px solid $color-base;\n  border-radius: 8px;\n  box-shadow: none;\n  padding: 12px;\n  text-align: center;\n\n  h5 {\n    font-size: 16px;\n    font-weight: bold;\n    margin-bottom: 10px;\n    text-align: center;\n  }\n\n  p {\n    line-height: 1.18;\n    text-align: center;\n  }\n\n  .mat-checkbox {\n    border: 6px;\n    left: -8px;\n    position: absolute;\n    top: -5px;\n  }\n}\n\n.no-shadow-card:hover {\n  transform: scale(1.02);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
