import {AfterViewInit, Component, ElementRef, OnInit, Output} from '@angular/core';
import {AMS_FEATURES_SHOW_API_V2} from '../../shared/constants';
import {Router} from '@angular/router';
import {SettingsService} from 'app/settings/services/settings.service';
import {
    CATEGORY_ROUTES,
    CUSTOMER_ROUTES,
    DASHBOARD_ROUTES,
    INVENTORY_ROUTES,
    ORDER_ROUTES,
    PARTNER_ROUTES,
    PRODUCT_ROUTES,
    PROMO_ROUTES,
    REPORT_ROUTES,
    SETTINGS_ROUTES,
    TASK_ROUTES,
    USER_ROUTES
} from '../../constants/common-constants';

declare const $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    permissions: any;
    type: string;
    children: any;
}

export const ROUTES: RouteInfo[] = [
    {
        path: DASHBOARD_ROUTES.DASHBOARD,
        title: 'Dashboard',
        icon: 'dashboardV3.png',
        class: '',
        permissions: ['MENU_DASHBOARD_MGT'],
        type: 'dashboard',
        children: []
    },
    {
        path: CUSTOMER_ROUTES.CUSTOMER_MGT,
        title: 'Customer Management',
        icon: 'dashboardV3.png',
        class: '',
        permissions: ['MENU_CUSTOMER_MGT'],
        type: 'analyticsDashboard',
        children: []
    },
    {
        path: PARTNER_ROUTES.PARTNER_MGT,
        title: 'Partner Management',
        icon: 'partnerManagement.png',
        class: '',
        permissions: ['MENU_MERCHANT_MGT'],
        type: 'partnerManagement',
        children: [
            {
                path: PARTNER_ROUTES.PARTNER_MGT,
                title: 'Partners',
                class: '',
                permissions: ['MENU_MERCHANT_MGT'],
                type: 'dropdownCategoryManagement',
                children: []
            },
            {
                path: PARTNER_ROUTES.COMMISSION_SETTINGS,
                title: 'Commission Settings',
                class: '',
                permissions: ['MENU_COMMISSION_SETTINGS'],
                type: 'dropdownCategoryManagement',
                children: []
            },
        ]
    },
    {
        path: ORDER_ROUTES.ORDER_MGT,
        title: 'Order Management',
        icon: 'tasksV3.png',
        class: '',
        permissions: ['MENU_ORDER_MGT'],
        type: 'orderManagement',
        children: [
            // TODO ENT Marketplacefo
            // {
            //     path: '/order-management/order-quotes',
            //     title: 'Order and Quotes',
            //     class: '',
            //     permissions: ['USER_VIEW'],
            //     children: [],
            //     type: 'orderManagement',
            // },
            {
                path: ORDER_ROUTES.PARTNER_ORDERS,
                title: 'Partner Orders',
                class: '',
                permissions: ['MENU_ORDER_MGT'],
                children: [],
                type: 'orderManagement',
            }
        ]
    },
    {
        path: PRODUCT_ROUTES.PRODUCT_MGT,
        title: 'Product Management',
        icon: 'producttab-image.png',
        class: '',
        permissions: ['MENU_PRODUCT_MGT'],
        type: 'productManagement',
        children: [
            {
                path: PRODUCT_ROUTES.PRODUCT_MGT,
                title: 'Product Creation',
                class: '',
                permissions: ['MENU_PRODUCT_MGT'],
                children: [],
                type: 'productManagement',
            },
            {
                path: PRODUCT_ROUTES.BULK_PRODUCT_MGT,
                title: 'Bulk Product Upload',
                class: '',
                permissions: ['ENABLE_PRODUCT_CREATE'],
                children: [],
                type: 'productManagement',
            },
            {
                path: PRODUCT_ROUTES.BULK_IMAGE_UPLOAD,
                title: 'Bulk Image Upload',
                class: '',
                permissions: ['ENABLE_PRODUCT_CREATE'],
                children: [],
                type: 'productManagement',
            }
        ]
    },
    {
        path: USER_ROUTES.USER_MGT,
        title: 'User Management',
        icon: 'user_managementV3.png',
        class: '',
        permissions: ['MENU_USER_MGT'],
        type: 'user',
        children: []
    },
    {
        path: TASK_ROUTES.TASK_MGT,
        title: 'Tasks',
        icon: 'tasksV3.png',
        class: '',
        permissions: ['MENU_TASK_MGT'],
        type: 'dropdownTasks',
        children: [
            {
                path: TASK_ROUTES.REGISTRATION,
                title: 'User Registration',
                class: '',
                permissions: ['MENU_TASK_MGT']
            },
        ]
    },
    {
        path: 'secure/service-configuration',
        title: 'Service Management',
        icon: 'tasksV3.png',
        class: '',
        permissions: ['MENU_SERVICE_MGT'],
        type: 'dropdownServiceConfiguration',
        children: [
            {
                path: '/service-configuration/product-type',
                title: 'Service Catalogs',
                class: '',
                permissions: ['MENU_SERVICE_MGT'],
                type: 'dropdownServiceConfiguration',
                children: [
                    {path: '/service-configuration/product-type/api', title: 'API', class: '', permissions: ['MENU_SERVICE_MGT']},
                    {path: '/service-configuration/product-type/saas', title: 'SAAS', class: '', permissions: ['MENU_SERVICE_MGT']},
                    {path: '/service-configuration/product-type/paas', title: 'PAAS', class: '', permissions: ['MENU_SERVICE_MGT']},
                    {path: '/service-configuration/product-type/iaas', title: 'IAAS', class: '', permissions: ['MENU_SERVICE_MGT']},
                    {path: '/service-configuration/product-type/caas', title: 'CAAS', class: '', permissions: ['MENU_SERVICE_MGT']},
                    {
                        path: '/service-configuration/product-type/resource',
                        title: 'RESOURCE',
                        class: '',
                        permissions: ['MENU_SERVICE_MGT']
                    },
                ]
            },
            {
                path: '/service-configuration/plugins',
                title: 'Service Configuration',
                class: '',
                permissions: ['MENU_SERVICE_MGT'],
                type: 'dropdownServiceConfiguration',
                children: []

            },

        ]
    },
    {
        path: SETTINGS_ROUTES.SETTINGS,
        title: 'System Settings',
        icon: 'settingsV3.png',
        class: '',
        permissions: ['MENU_SETTINGS_MGT'],
        type: 'dropdownSettings',
        children: [
            {path: '/settings/add/tenant', title: 'Tenant', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {
                path: '/settings/user-account-settings',
                title: 'User Account',
                class: '',
                permissions: ['MENU_SETTINGS_MGT']
            },
            {path: '/settings/password-settings', title: 'Password', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {
                path: '/settings/terms-and-conditions-settings',
                title: 'Terms and Conditions',
                class: '',
                permissions: ['MENU_SETTINGS_MGT']
            },
            {path: '/settings/security-settings', title: 'Security', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/localization/index', title: 'Localization', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/notification/index', title: 'Notifications', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/theming/index', title: 'Theming', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/products/index', title: 'Products', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/cms/index', title: 'Content Management', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/notification-group/index', title: 'Notification Group', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/security/index', title: 'Security', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/email-content-security/index', title: 'Email Content', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/api-gateway/index', title: 'API Gateway', class: '', permissions: ['MENU_SETTINGS_MGT']},
            {path: '/settings/site-wordings/index', title: 'Site wordings', class: '', permissions: ['MENU_SETTINGS_MGT']},
        ]
    },
    {
        path: CATEGORY_ROUTES.CATEGORY_MGT,
        title: 'Category Management',
        icon: 'tasksV3.png',
        class: '',
        permissions: ['MENU_CATEGORY_MGT'],
        type: 'dropdownCategoryManagement',
        children: [
            {
                path: CATEGORY_ROUTES.CATEGORY_LIST,
                title: 'Category',
                class: '',
                permissions: ['MENU_CATEGORY_MGT'],
                type: 'dropdownCategoryManagement',
                children: []
            },
            {
                path: CATEGORY_ROUTES.ATTRIBUTES_LIST,
                title: 'Attributes',
                class: '',
                permissions: ['MENU_ATTRIBUTE_MGT'],
                type: 'dropdownCategoryManagement',
                children: []
            },

        ]
    },
    {
        path: PROMO_ROUTES.PROMO_MGT,
        title: 'Promotions & Campaigns',
        icon: 'promotion.png',
        class: '',
        permissions: ['MENU_PROMOTION_MGT'],
        type: 'promotions',
        children: [
            {
                path: PROMO_ROUTES.PROMOTIONS_LIST,
                title: 'Promotions',
                class: '',
                permissions: ['MENU_PROMOTION_MGT'],
                type: 'promotions',
                children: []
            },
            {
                path: PROMO_ROUTES.CAMPAIGNS_LIST,
                title: 'Campaigns',
                class: '',
                permissions: ['MENU_PROMOTION_MGT'],
                type: 'promotions',
                children: []
            }
        ]
    },
    {
        path: INVENTORY_ROUTES.INVENTORY_MGT,
        title: 'Inventory Management',
        icon: 'tasksV3.png',
        class: '',
        permissions: ['MENU_INVENTORY_MGT'],
        type: 'inventoryManagement',
        children: []
    },
    {
        path: REPORT_ROUTES.REPORT_MGT,
        title: 'Report Management',
        icon: 'reportManagement.png',
        class: '',
        permissions: ['MENU_REPORT_MGT'],
        type: 'reportManagement',
        children: []
    }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    host: {
        '(document:click)': 'onClick($event)',
    }
})
export class SidebarComponent implements OnInit, AfterViewInit {
    menuItems: any[];
    API_V2_ENABLED: string;
    @Output('selectedMenuName') selectedMenuName: string;
    spMainLogo: string;
    spMainLogoMini: string;
    $event: any;

    constructor(
        private _eref: ElementRef,
        public router: Router,
        private settingsService: SettingsService) {
    }

    ngAfterViewInit() {
        setTimeout(function () {
            const activeMenuId = document.querySelector('.active')?.id;
            if (!!activeMenuId && activeMenuId !== 'dropdownCatalog') {
                const activeSubMenuId: any = document.getElementById(activeMenuId)?.getElementsByClassName('dropdown-content')[0]?.id;
                document.getElementById(activeSubMenuId)!.classList.add('show');
            }
        }, 1000);
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.API_V2_ENABLED = sessionStorage.getItem(AMS_FEATURES_SHOW_API_V2) ?? '';
        this.settingsService.getConfigurationByCategoryFromSMS('THEME').subscribe(
            (resp) => {
                if (!!resp) {
                    resp.forEach((value: any, index: any) => {

                        if (value.name === 'SP_MAIN_LOGO') {
                            this.spMainLogo = './assets/resources/' + value.value;
                        }
                        if (value.name === 'SP_FAVICON') {
                            this.spMainLogoMini = './assets/resources/' + value.value;
                        }
                    });
                }
            },
            (err) => {
            }
        );
        this.settingsService.getConfigurationByCategoryFromSMS('SIDE_MENUS').subscribe(
            (resp) => {
                if (!!resp) {
                    let settingMenu = this.menuItems.filter(
                        (item) =>
                            item.title.includes('System Settings') &&
                            item.path.includes('/settings'));
                    resp.forEach((value: any, index: any) => {
                        if (value.name === 'SECURITY' && (value.value === 'false' || value.value === '0')) {

                            settingMenu[0].children = settingMenu[0].children.filter((val: any) => !val.title.includes('Security'));
                        }
                        if (value.name === 'API_GATEWAY' && (value.value === 'false' || value.value === '0')) {

                            settingMenu[0].children = settingMenu[0].children.filter((val: any) => !val.title.includes('API Gateway'));
                        }
                        if (value.name === 'SITE_WORDING' && (value.value === 'false' || value.value === '0')) {

                            settingMenu[0].children = settingMenu[0].children.filter((val: any) => !val.title.includes('Site wordings'));
                        }
                    });
                }
            },
            (err) => {
            }
        );
    }

    selectedMenu(selected: any) {

    }

    toggleMenu(element: any) {
        const activeMenuId = document.querySelector('.show')?.id;
        if (!!activeMenuId && activeMenuId !== 'dropdownCatalog') {
            document.getElementById(activeMenuId)!.classList.remove('show');
        }
        document.getElementById(element)!.classList.toggle('show');
        document.getElementById(element + 'Icon')!.classList.toggle('caretup');
    }

    onClick(event: any) {

        if (!this._eref.nativeElement.contains(event.target)) {

        }
    }
}
