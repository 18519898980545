import {Injectable} from '@angular/core';

const ACCESS_TOKEN = 'fw_access_token';
const REFRESH_TOKEN = 'fw_refresh_token';
const MFA_TOKEN = 'fw_mfa_token';
const MFA_TYPE = 'mfa_type';
const MFA_MASKED_NUMBER = 'mfa_masked_number';
const USER_ROLE = 'user_role';
const OTP_EXPIRE_TIME = 'otp_expire_time';
const ROLE_PERMISSION = 'permissions';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    constructor() {
    }

    getToken(): any {
        return localStorage.getItem(ACCESS_TOKEN);
    }

    getRefreshToken(): any {
        return localStorage.getItem(REFRESH_TOKEN);
    }

    getMfaToken(): any {
        return localStorage.getItem(MFA_TOKEN);
    }

    getMfaType(): any {
        return localStorage.getItem(MFA_TYPE);
    }

    getUserMaskedNumber(): any {
        return localStorage.getItem(MFA_MASKED_NUMBER);
    }

    getUserRole(): any {
        return localStorage.getItem(USER_ROLE);
    }

    saveToken(token: any): void {
        localStorage.setItem(ACCESS_TOKEN, token);
    }

    saveRefreshToken(refreshToken: any): void {
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }

    saveMfaToken(token: any): void {
        localStorage.setItem(MFA_TOKEN, token);
    }

    saveMfaType(type: any): void {
        localStorage.setItem(MFA_TYPE, type);
    }

    saveUserMaskedNumber(numder: any): void {
        localStorage.setItem(MFA_MASKED_NUMBER, numder);
    }

    saveUserRole(role: any): void {
        localStorage.setItem(USER_ROLE, role);
    }

    saveOtpExpireTime(time: any): void {
        localStorage.setItem(OTP_EXPIRE_TIME, time);
    }

    getOtpExpireTime(): any {
        return localStorage.getItem(OTP_EXPIRE_TIME);
    }

    removeOtpExpireTime(): void {
        localStorage.removeItem(OTP_EXPIRE_TIME);
    }

    removeToken(): void {
        localStorage.removeItem(ACCESS_TOKEN);
    }

    removeRefreshToken(): void {
        localStorage.removeItem(REFRESH_TOKEN);
    }

    removeMfaToken(): void {
        localStorage.removeItem(MFA_TOKEN);
    }

    removeUserMaskedNumber(): void {
        localStorage.removeItem(MFA_MASKED_NUMBER);
    }

    removeMfaType(): void {
        localStorage.removeItem(MFA_TYPE);
    }

    removeUserRole(): void {
        localStorage.removeItem(USER_ROLE);
    }

    setRolePermission(rolePermission: any): void {
        localStorage.setItem(ROLE_PERMISSION, rolePermission);
    }

    getRolePermission(): string[] {
        return JSON.parse(localStorage.getItem(ROLE_PERMISSION) as string);
    }

    removeRolePermission(): void {
        localStorage.removeItem(ROLE_PERMISSION);
    }

    flushAllSessions() {
        this.removeToken();
        this.removeRefreshToken();
        this.removeMfaToken();
        this.removeUserMaskedNumber();
        this.removeMfaType();
        this.removeUserRole()
        this.removeRolePermission();
        this.removeOtpExpireTime();
    }
}
